<template>
  <r-chart>
    <h3 v-if="title && title!=''" slot="title">{{title}}</h3>
    <v-chart slot="chart" :forceFit="true" :height="height" :data="data">
      <v-tooltip />
      <v-axis />
      <v-bar :position="nombre + '*' + valor" />
    </v-chart>
    <slot name="filter" slot="filter"/>
  </r-chart>
</template>

<script>
  import rChart from "./rChart.vue";
  export default {
    extends: rChart,
    components: {
      rChart,
    },
    data() {
      return {
      };
    },
    props: {

    }
  };
</script>