<template>
  <f7-popup ref="popup" v-if="popupAbierto" :opened="true" class="popup-estimar-fecha" @popup:closed="popupAbierto = false; " swipe-to-close>
    <f7-page>
      <f7-navbar :title="$t('inspecciones.inspeccion_form.estimar_tarea')">
        <f7-nav-right>
          <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
        </f7-nav-right>
      </f7-navbar>
    <f7-card
      class="elevation-3 padding-vertical padding-right"
    >
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="inspeccion?inspeccion.id:null"
      :readonly="false"
      :initialData="inspeccion ? inspeccion : null"
      :defaultData="getDefaultData(inspeccion)"
      :beforeSave="beforeSave"
      @save="onSave"
    >
      <f7-card-content>
        <f7-list class="list-form">
          <ul>
            <f7-row>
              <f7-col width="100" medium="100">
                <r-input
                  class="col"
                  floating-label
                  :label="$t('inspecciones.inspeccion_form.fechaEntradaDepartamento')"
                  :form="rform"
                  type="date"
                  name="situacion.fechaEntradaAux"
                ></r-input>
              </f7-col>
            </f7-row>
            <f7-row>
              <f7-col width="100" medium="100">
                <r-input
                  class="col"
                  floating-label
                  :label="$t('inspecciones.inspeccion_form.fechaPrevistaTarea')"
                  :form="rform"
                  type="date"
                  name="situacion.fechaPrevistaAux"
                ></r-input>
              </f7-col>
            </f7-row>
            <f7-row>
              <f7-col width="100" medium="100">
                <r-input
                  class="col"
                  floating-label
                  :label="$t('inspecciones.inspeccion_form.motivo_situacion')"
                  :form="rform"
                  type="textarea"
                  name="situacion.motivo"
                ></r-input>
              </f7-col>
            </f7-row>
          </ul>
        </f7-list>
      </f7-card-content>
    </r-form>
    <f7-button
        fill
        icon-f7="floppy_disk"
        text="Guardar"
        class="display-flex margin-left"
        @click="$refs.rform.saveItem()"
      ></f7-button>
    </f7-card>
      <!-- </div> -->
    </f7-page>
  </f7-popup>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rForm from "../../../components/rForm.vue";
import rInput from "../../../components/rInput.vue";
import Inspeccion from "../../../js/db/Inspeccion.js";
import Utils from "../../../js/Utils.js";

export default {
  components: {
    rForm,
    rInput
  },
  data() {
    return {
      Inspeccion,
      Utils,
      popupAbierto: false
    };
  },
  props:{
    inspeccion:{
      type: Object
    }
  },
  computed: {},
  methods: {
    getDefaultData: function(inspeccion) {
      if (!inspeccion) {
        return null;
      }
      if (!inspeccion.situacion) {
        inspeccion.situacion = {};
      }
      inspeccion.situacion.motivo = "Estimación de fechas de inicio y fín de la tarea";
      return inspeccion;
      
    },
    preparado: function(item) {
      return item.estado && item.estado.estado && item.estado.estado == "EN_TALLER" && item.situacion && !item.situacion.fechaPrevista;
    },
    openPopup: function() {
      const self = this;
      self.popupAbierto = true;
    },
    beforeSave: function(data, resolve, reject) {
      data.situacion.fechaPrevista = data.situacion.fechaPrevistaAux;
      data.situacion.fechaEntrada = data.situacion.fechaEntradaAux;
      resolve(data);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      Inspeccion.editSituacion(res).then(res => {
        self.inspeccion = null;
        // if (self.$refs.popup) {
        //   self.$refs.popup.close();
        // }
        if (self.$refs.rform) {
          self.$refs.rform.clear();
        }
        app.toast.create({
            text: self.$t("inspecciones.inspeccion_form.inspeccion_guardada"),
            position: "center",
            closeTimeout: 2000,
            icon: '<i class="f7-icons">checkmark_alt</i>'
        }).open();
        self.$emit("finished");
      });
      if (self.$refs.popup) {
        self.$refs.popup.close();
      }
    }
  }
};
</script>