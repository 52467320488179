import DatabaseModel from './DatabaseModel.js';

var Cliente = Object.create(DatabaseModel);

Cliente.model = 'cliente';

Cliente.clienteContactos = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server + self.prefix + self.model + '/' + params.itemId + '/clienteContactos',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Cliente.getConfiguraciones = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + self.prefix + self.model + '/getConfiguraciones',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.list);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                var obj = JSON.parse(xhr.response);
                reject(obj.message ? obj.message : obj.error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

export default Cliente;