import DatabaseModel from './DatabaseModel.js';

var Anyo = Object.create(DatabaseModel);

Anyo.model = 'combos/anyos';

Anyo.getList = function(params) {
    var self = this;
    params = params || { num: 1 };
    return new Promise(function(resolve, reject) {
        //var params_ser = '';
        self.request.promise({
            url: self.server + self.prefix + self.model + '/' + params.num,
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: {}
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

export default Anyo;