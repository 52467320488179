<template>
  <f7-app :params="f7params">
    <f7-appbar>
      <div class="left">
        <f7-button small panel-toggle="left" class="display-flex" icon-f7="bars"></f7-button>
        <img src="/static/img/logo.svg" style="max-height: 3rem;" />
      </div>
      
      <div class="right">
        <f7-button small class="display-flex" icon-f7="lightbulb" popup-open=".popup-sugerencia"> Sugerencia de Mejora</f7-button>
        <f7-button small class="display-flex" icon-f7="question_circle_fill" @click="popupAyudaOpened=true"></f7-button>
      </div>
      <f7-popup v-if="popupAyudaOpened" :opened="true" @popup:closed="popupAyudaOpened = false">
        <f7-page>
          <f7-navbar title="Ayuda">
            <f7-nav-right>
              <f7-link popup-close icon-f7="multiply"></f7-link>
            </f7-nav-right>
          </f7-navbar>
          <div class="list no-hairlines no-margin full-height">
            <ul class="full-height">
              <li class="item-content item-input full-height">
                <div class="item-inner full-height">
                  <div class="item-input-wrap full-height">
                    Si necesita ayuda o tiene cualquier problema con el uso de la aplicación puede contactar con:
                    <br/>
                    <br/>
                    <f7-link href="mailto:soporte@digitaltpm.com" class="justify-left" external>soporte@digitaltpm.com</f7-link>
                    <!-- <f7-link href="tel:+34918326123" class="justify-left" external>918326123</f7-link> -->
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </f7-page>
      </f7-popup>
      <f7-popup ref="popupSugerencia" class="popup-sugerencia" swipe-to-close>
        <f7-page>
          <f7-navbar title="Sugerencia de Mejora">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card
          class="elevation-3 padding-vertical-half padding-right"
        >
        <f7-card-content>
          <textarea
            floating-label
            placeholder="Sugerencia"
            name="sugerenciaMejora"
            ref="sugerenciaMejora"
            class="padding-left margin-right"
          ></textarea>
        </f7-card-content>
        <f7-button
            fill
            icon-f7="paperplane"
            text="Enviar"
            class="margin-left display-flex"
            color="blue"
            @click="sendSugerencia"
          ></f7-button>
        </f7-card>
          <!-- </div> -->
        </f7-page>
      </f7-popup>
    </f7-appbar>
    <!-- Left panel with cover effect-->
    <f7-panel left cover :visibleBreakpoint="1024" >
      <f7-view class="view-left" :init="false"></f7-view>
    </f7-panel>

    <!-- Your main view, should have "view-main" class -->
    <f7-view main class="safe-areas" url="/" :init="false"></f7-view>
  </f7-app>
</template>
<style scoped>
  textarea {
    border: none;
    width: 100%;
    min-height: 200px;
  }
  .justify-left {
    justify-content: initial;
  }
</style>
<script>
import cordovaApp from "../js/cordova-app.js";
import { routes, routesLeft } from "../js/routes.js";
import Usuario from "./../js/db/Usuario.js";
import Sugerencia from "./../js/db/Sugerencia.js";
import UsuarioCliente from "./../js/db/UsuarioCliente.js";
export default {
  methods: {
    sendSugerencia: function() {
      var self = this;
      Sugerencia.post({data: {sugerencia: self.$refs.sugerenciaMejora.value}}).then(function(res) {
        self.$refs.sugerenciaMejora.value = '';
        if (self.$refs.popupSugerencia) {
          self.$refs.popupSugerencia.close(true);
        }
      }).catch(function(error) {
        var app = self.$f7;
        app.dialog.alert(error.message);
      })
    }
  },
  data() {
    return {
      popupAyudaOpened: false,
      // Framework7 Parameters
      f7params: {
        id: "com.digitaltpm.givo", // App bundle ID
        name: "GIVO", // App name
        theme: "aurora", // Automatic theme detection
        // App root data
        data: function() {
          return {
            URL_API: "",
            user: {}
          };
        },
        methods: {
          checkAuth: function() {
            return this.data.session ? true : false;
          },
          checkPermission: function(pagename) {
            return true;
          },
          startSession: function() {
            var session = localStorage.getItem("session");
            if (typeof session != "undefined" && session) {
              var sessionObj = JSON.parse(session);
              this.data.session = sessionObj;
              this.request.setup({
                headers: {
                  Authorization: "Bearer " + sessionObj.token
                }
              });
              var user = localStorage.getItem("user");
              var redirect = false;
              if (typeof user == "undefined" || !user) {
                redirect = true;
              }
              this.methods.loadGlobals(redirect);
            }
            //TODO: Load user data
          },
          saveSession: function(obj) {
            this.data.session = obj;
            localStorage.setItem("session", JSON.stringify(obj));
          },
          removeSession: function() {
            delete this.data.session;
            localStorage.removeItem("session");
            localStorage.removeItem("user");
          },
          loadGlobals: function(redirect) {
            var app = this;
            Usuario.me()
              .then(function(res) {
                var user = res;
                if (user.roles.indexOf('TECNICO') > -1) {
                  UsuarioCliente.me().then(function(res){
                    user.clientes = res && res.clientes ? res.clientes : [];
                    app.data.user = user;
                    localStorage.setItem("user", JSON.stringify(user));
                    if (redirect) {
                      app.views.main.router.navigate("/");
                      app.views.left.router.navigate("/main-menu/");
                    }
                  }).catch(function(e) {
                    app.data.user = user;
                    localStorage.setItem("user", JSON.stringify(user));
                    if (redirect) {
                      app.views.main.router.navigate("/");
                      app.views.left.router.navigate("/main-menu/");
                    }
                  });
                } else {
                  app.data.user = user;
                  localStorage.setItem("user", JSON.stringify(user));
                  if (redirect) {
                    app.views.main.router.navigate("/");
                    app.views.left.router.navigate("/main-menu/");
                  }
                }

              })
              .catch(function(e) {
                app.toast
                  .create({
                    text: e.error_description,
                    position: "center",
                    closeTimeout: 2000
                  })
                  .open();
                  if(e.error=='invalid_token'){
                    app.methods.removeSession();
                    app.views.main.router.navigate("/login/");
                  }
              });
          }
        },

        // App routes
        routes: routes,

        // Input settings
        input: {
          scrollIntoViewOnFocus: this.$device && this.$device.cordova && !this.$device.electron,
          scrollIntoViewCentered: this.$device && this.$device.cordova && !this.$device.electron
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false
        },

        toast: {
          destroyOnClose: true
        }
      },
      Sugerencia
    };
  },
  mounted() {
    this.$f7ready(f7 => {
      var self = this;
      // Init cordova APIs (see cordova-app.js)
      if (f7.device.cordova) {
        cordovaApp.init(f7);
      }
      // Call F7 APIs here
      var app = this.$f7;
      
      app.methods.startSession();

      var leftView = app.views.create(".view-left", {
        name: "left",
        routes: routesLeft,
        url: "/main-menu/"
      });
      var mainView = app.views.create(".view-main", {
        url: "/pedidos/",
        preloadPreviousPage: false,
        pushState: true,
        xhrCache: false
        /*pushStateSeparator: "",
        iosSeparateDynamicNavbar: true,
        iosDynamicNavbar: false,
        xhrCache: false*/
        /*pushStateRoot: "/",*/
      });
      //FIX external links inAppBrowser
      this.$$(this.$el).on("click", "a", function(e) {
        if (self.$$(this).hasClass("external")) {
          window.open(this.href, "_system");
          e.preventDefault();
          return false;
        }
      });
    });
  }
};
</script>