<template>
  <f7-popup ref="popup" v-if="popupAbierto" :opened="true" class="popup-orden-trabajo"
      @popup:closed="popupAbierto = false;" @popup:open="preparePopUpOrdenTaller()" swipe-to-close>
    <f7-page>
      <r-form
        ref="rform"
        v-slot="{rform}"
        :readonly="false"
        @save="generarOrdenTaller();"
      >
        <f7-navbar >
          <f7-nav-left v-if="inspeccion && inspeccion.ordenesDeTaller">
            <div class="list">
              <f7-list-input
                class="ensanchar"
                @change="verOrdenTaller"
                :readonly="false"
                v-tooltip="'Ordenes de taller del certificado'"
                type="select"
                outline
              >
                <option selected disabled></option>
                <option v-for="(ordenTaller,index) in inspeccion.ordenesDeTaller"
                    :key="'ordentallerseleccion-'+index" :value="index">{{DateUtils.dateFormat(new Date(ordenTaller.fechaEnvio), "DD/MM/YYYY HH:mm:ss")}}</option>
              </f7-list-input>
            </div>
          </f7-nav-left>
          <f7-nav-title sliding>Orden de taller</f7-nav-title>
          <f7-nav-right>
            <f7-link @click="preparePopUpOrdenTaller">Nueva orden</f7-link>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <div>
          <f7-card class="elevation-3 padding-vertical-half padding-right"
              v-if="rform && rform.formData && rform.formData.ordenTaller && rform.formData.ordenTaller.camposCabeceraLista">
            <f7-card-content>
              {{$t('inspecciones.ordentaller.cabecera')}}
              <div>
                <div class="list"
                  v-for="(campo,index) in rform.formData.ordenTaller.camposCabeceraLista" 
                  :key="index + '_' + campo.nombre"
                >
                  <r-input
                    class="col"
                    outline
                    floating-label
                    :label="campo.texto"
                    :form="rform"
                    :type="campo.type"
                    :clearButton="false"
                    :name="'ordenTaller.camposCabecera.' + campo.codigo"
                    :readonly="false"
                  >
                    <!-- :readonly="!!rform.formData.ordenTaller && !!rform.formData.ordenTaller.estado" -->
                  </r-input>
                </div>
              </div>
            </f7-card-content>
          </f7-card>
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <ul v-if="rform && rform.formData && rform.formData.ordenTaller && rform.formData.ordenTaller.trabajos">
                  <f7-row>
                    <f7-col width="100" medium="80" style="margin-left:10px;">
                      <b>{{$t('inspecciones.ordentaller.trabajos')}}</b>
                      <!-- <span 
                        v-for="(campo,index) in rform.formData.ordenTaller.camposExtraLista" 
                        :key="index + '_' + campo.nombre"
                      ><b>{{campo.texto}}</b></span> -->
                      
                    </f7-col>
                    <f7-col width="50" medium="5">
                      <div v-tooltip="'Trabajo mecánico'"><i class="icon f7-icons">wrench</i></div>
                    </f7-col>
                    <f7-col width="50" medium="5">
                      <div v-tooltip="'Trabajo de carrocería y pintura'"><i class="icon f7-icons">hammer</i></div>
                    </f7-col>
                    <f7-col width="100" medium="5">

                    </f7-col>
                  </f7-row>
                </ul>
                <ul v-if="rform && rform.formData && rform.formData.ordenTaller && rform.formData.ordenTaller.trabajos" style="padding-left:10px;">
                  <f7-row 
                    v-for="(trabajo,index) in rform.formData.ordenTaller.trabajos" 
                    :key="'trabajo'+index"
                    class=""
                    style="padding-left:5px;border-bottom: 1px solid #e3dcdc;"
                  >
                    <f7-col width="100" :medium="'80'">
                      <r-input
                        :form="rform"
                        type="text"
                        :clearButton="false"
                        :name="'ordenTaller.trabajos.' + index + '.nombre'"
                        v-tooltip="trabajo.nombre"
                        :readonly="false"
                        :overrideReadonly="true"
                      ></r-input>
                    </f7-col>
                    <f7-col width="100" medium="80" v-if="rform.formData.ordenTaller.camposExtraLista && rform.formData.ordenTaller.camposExtraLista.length">
                      <r-input
                        v-for="(campoExtra,indexCE) in rform.formData.ordenTaller.camposExtraLista" 
                        :key="campoExtra.codigo+'['+index+','+indexCE+']'"
                        :form="rform"
                        :type="campoExtra.type"
                        :clearButton="false"
                        :name="'ordenTaller.trabajos.' + index + '.camposPersonalizados.'+campoExtra.codigo"
                        floating-label
                        :label="campoExtra.texto"
                        v-tooltip="campoExtra.texto"
                        :readonly="false"
                        :overrideReadonly="true"
                      >
                        <option selected disabled></option>
                        <option v-for="(item,indexOption) in campoExtra.options"
                            :key="'ordenTaller.trabajos.' + index + '.camposPersonalizados.'+campoExtra.codigo+'-'+index+indexOption" :value="item">{{item}}</option>
                      </r-input>
                        <!-- :readonly="!!rform.formData.ordenTaller && !!rform.formData.ordenTaller.estado" -->
                    </f7-col>
                    <f7-col width="100" medium="5">
                      <f7-radio 
                        v-tooltip="'MECANICO'"
                        :name="'ordenTaller.trabajos.' + index + '.tipoTrabajo'" 
                        icon-f7="wrench"
                        value="MECANICO"
                        :checked="esTrabajoMecanico(trabajo)"
                        @change="trabajo.tipoTrabajo = 'CARROCERIA'"
                        :readonly="false"
                        :disabled="false"
                        />
                        <!-- :readonly="!!rform.formData.ordenTaller && !!rform.formData.ordenTaller.estado"
                        :disabled="!!rform.formData.ordenTaller && !!rform.formData.ordenTaller.estado" -->
                    </f7-col>
                    <f7-col width="100" medium="5" >
                      <f7-radio 
                        v-tooltip="'CARROCERIA'"
                        :name="'ordenTaller.trabajos.' + index + '.tipoTrabajo'" 
                        value="CARROCERIA"
                        :checked="trabajo.tipoTrabajo == 'CARROCERIA'"
                        @change="trabajo.tipoTrabajo = 'CARROCERIA'"
                        :readonly="false"
                        :disabled="false"
                      />
                        <!-- :readonly="!!rform.formData.ordenTaller && !!rform.formData.ordenTaller.estado"
                        :disabled="!!rform.formData.ordenTaller.estado" -->
                    </f7-col>
                    <f7-col width="100" medium="5">
                        <!-- v-if="!!rform.formData.ordenTaller && !rform.formData.ordenTaller.estado" -->
                      <f7-link
                        v-tooltip="'Borrar'"
                        class="input-suffix-btn"
                        @click="$delete(rform.formData.ordenTaller.trabajos,index);"
                        icon-f7="trash"
                        color="red"
                        style="margin-top: 3px;top: initial;right: 5px;"
                      ></f7-link>
                    </f7-col>
                  </f7-row>
                </ul>
                <ul>
                  <f7-row style="height: 20px;">
                    <f7-col width="100" medium="100">
                        <!-- v-if="!!rform.formData.ordenTaller && !rform.formData.ordenTaller.estado" -->
                      <f7-link 
                        v-tooltip="'Añadir Trabajo'"
                        @click="addTrabajo()"
                        class="input-suffix-btn"
                        icon-f7="rectangle_stack_badge_plus"
                        color="black"
                        style="margin-top: 5px;top: initial;left: 15px;"
                      ></f7-link>
                    </f7-col>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
            <!-- v-if="rform && rform.formData && rform.formData.ordenTaller && rform.formData.ordenTaller.destinatarios" -->
          <f7-card 
            class="elevation-3 padding-vertical-half padding-right"
          >
            <f7-card-content>
              <f7-list class="list-form">
                <template class="col-100">
                  <div class="form-caja">
                    <div v-if="!rform.formData || !rform.formData.ordenTaller || !rform.formData.ordenTaller.destinatarios || !rform.formData.ordenTaller.destinatarios.length"
                      class="margin text-color-red" 
                    >* Ningún correo electrónico introducido</div>
                    <div
                      class="col-100"
                      v-for="(email, index) in (rform && rform.formData && rform.formData.ordenTaller && rform.formData.ordenTaller.destinatarios?rform.formData.ordenTaller.destinatarios:[])"
                      :key="'destinatarios-' + index + '-email'"
                    >
                      <f7-row>
                        <f7-col width="90" medium="90">
                          <r-input
                            outline
                            floating-label
                            :label="'Correo electrónico'"
                            :form="rform"
                            type="text"
                            :clearButton="false"
                            :placeholder="'Introduzca un correo electrónico válido'"
                            :name="'ordenTaller.destinatarios.' + index"
                          >
                            <!-- :validation="{isValid: validator.isEmail, errorMessage: '* Introduzca un correo electrónico válido'}" -->
                          </r-input>
                        </f7-col>
                        <f7-col width="10" medium="10">
                          <f7-link
                            v-tooltip="'Borrar'"
                            class="input-suffix-btn"
                            @click="$delete(rform.formData.ordenTaller.destinatarios,index);"
                            icon-f7="trash"
                            color="red"
                            style="top: initial;right: 15px;"
                          ></f7-link>
                        </f7-col>
                      </f7-row>
                    </div>
                  </div>
                </template>
                    <!-- v-if="rform && rform.formData && rform.formData.ordenTaller && !rform.formData.ordenTaller.id" -->
                  <div 
                    class="form-caja" 
                  >
                    <f7-block-title>
                      <f7-link 
                        class="col-90"
                        v-tooltip="'Añadir e-Mail'"
                        @click="addEmail()"
                        icon-f7="at_badge_plus"
                        color="black"
                      ></f7-link>
                    </f7-block-title>
                  </div>
                  <!-- Botón de generación y envío de orden de taller -->
                    <!-- v-if="rform && rform.formData && rform.formData.ordenTaller && !rform.formData.ordenTaller.id" -->
                  <f7-row 
                    class="col-90"
                  >
                      <f7-button
                        class="display-flex margin-left col-100"
                        fill
                        icon-f7="wrench"
                        text="Generar y enviar"
                        color="gray"
                        @click="rform.saveItem();"
                      ></f7-button>
                  </f7-row>
              </f7-list>
            </f7-card-content>

          </f7-card>
        </div>
      </r-form>
    </f7-page>
  </f7-popup>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rForm from "../../../components/rForm.vue";
import rInput from "../../../components/rInput.vue";
import Inspeccion from "../../../js/db/Inspeccion.js";
import Departamento from "../../../js/db/Departamento.js";
import Utils from "../../../js/Utils.js";
import Property from "./../../../js/db/Property.js";
import Cliente from "./../../../js/db/Cliente.js";
import DateUtils from "./../../../js/DateUtils.js";

export default {
  components: {
    rForm,
    rInput
  },
  data() {
    return {
      Inspeccion,
      Departamento,
      Utils,
      Property,
      Cliente,
      departamentos: [],
      popupAbierto: false,
      configuraciones: [],
      DateUtils
    };
  },
  props:{
    inspeccion:{
      type: Object
    },
  },
  computed: {
    popupTitle: function(){
      const self = this;
      return !self.todosEstados ? self.$t("inspecciones.inspeccion_form.mandarATaller") : self.$t("inspecciones.inspeccion_form.cambiarEstado");
    }
  },
  methods: {
    preparado: function(item) {
      return item.estado && item.estado.estado && item.estado.estado == "PERITADO";
    },
    openPopup: function(inspeccion) {
      const self = this;

      if(inspeccion == null) {
        inspeccion = self.inspeccion;
      }
      
      Cliente.getConfiguraciones({data: {idInspeccion: inspeccion.id}}).then(function(res) {
        self.configuraciones = res;
        self.popupAbierto = true;
      }).catch(function(error) {
        if (error.message) {
          app.dialog.alert(error.message);
        } else {
          app.dialog.alert(error);
        }
      });

    },
    preparePopUpOrdenTaller: function () {
      var self = this;
      var app = self.$f7;
      var nuevaOT = {trabajos:[]},
        inspeccion = self.inspeccion,
        configuraciones = self.configuraciones;
      console.log("preparando");
      if(inspeccion && inspeccion.comprobaciones && inspeccion.comprobaciones.length > 0) {
        var camposCabecera = [];
        if(configuraciones && configuraciones.find(c => c.tipoConfiguracion == 'ORDEN_TALLER_CABECERA')) {
          // vamos a recuperar el nombre de la property que contiene las cabeceras
          var nombreProperty = configuraciones.find(c => c.tipoConfiguracion == 'ORDEN_TALLER_CABECERA');
          var configuracionCabecera = [];
          if( nombreProperty ) {
            Property.getList({filter: {nombre: nombreProperty.valor} }).then(function(res) {
              configuracionCabecera = res.list[0];
              camposCabecera = JSON.parse(configuracionCabecera.valor);
              self.$set(self.$refs.rform.formData.ordenTaller, 'camposCabeceraLista', camposCabecera);
              nuevaOT = {
                idInspeccion: inspeccion.id,
                camposCabeceraLista: camposCabecera,
                camposCabecera: null,
                camposExtraLista: camposExtra,
                trabajos: [],
                destinatarios: [""],
                inspeccion: inspeccion
              };

              // vamos a recuperar el nombre de la property que contiene los campos extra en el formato
              let camposExtra = [];
              if(configuraciones && configuraciones.find(c => c.tipoConfiguracion == 'ORDEN_TALLER_FORMATO')) {
                var configuracionFormato = [];
                var nombreFormato = configuraciones.find(c => c.tipoConfiguracion == 'ORDEN_TALLER_FORMATO');
                if( nombreFormato ) {
                  Property.getList({filter: {nombre: 'OT_FORMATOS'} }).then(function(res) {
                    configuracionFormato = res.list[0];
                    let formatos = JSON.parse(configuracionFormato.valor);
                    let formato = formatos.find(f => f.nombre == nombreFormato.valor);
                    camposExtra = formato?formato.camposExtra:[];
                    self.$set(self.$refs.rform.formData.ordenTaller, 'camposExtraLista', camposExtra);
                    self.$set(self.$refs.rform.formData.ordenTaller, 'camposExtra', camposExtra);
                  }).catch(function(error) {
                    if (error.message) {
                      app.dialog.alert(error.message);
                    } else {
                      app.dialog.alert(error);
                    }
                    self.$refs.popup.close();
                  });
                }
              }
            }).catch(function(error) {
              if (error.message) {
                app.dialog.alert(error.message);
              } else {
                app.dialog.alert(error);
              }
              self.$refs.popup.close()
            });
          }
        }
        if(inspeccion.ordenesDeTaller && inspeccion.ordenesDeTaller.length > 0) {
          
        } 
        if(inspeccion && inspeccion.comprobaciones && inspeccion.comprobaciones.length > 0) {
          var defectos = self.getDefectos();
          defectos.forEach(d => {
            nuevaOT.trabajos.push({
              nombre: ' ' + d.nombre + ' - ' + (d.comentarios ? d.comentarios : '') ,
              tipoTrabajo: null,
              realizado: false
            });
          });
        }
        // self.$set(self.$refs[formulario].formData, 'ordenTaller', nuevaOT); 
        self.$set(self.$refs.rform.formData, 'ordenTaller', nuevaOT); 
      }
    },
    addTrabajo: function() {
      var self = this,
        formData = self.$refs.rform.formData,
        ordenTaller = formData.ordenTaller ? formData.ordenTaller : {},
        arr = ordenTaller.trabajos? ordenTaller.trabajos : [];
      if(!formData.ordenTaller){
        self.$set(formData, 'ordenTaller', {});
      }
      arr.push({});
      self.$set(formData.ordenTaller, 'trabajos', arr);
    },
    getDefectos: function (){
      var self = this,
        inspeccion = self.inspeccion;
      return self.getComprobacionesDefectos(inspeccion.comprobaciones);
    },
    getComprobacionesDefectos: function (comprobaciones){
      var self = this;
      var result = [];
      if(comprobaciones) {
        comprobaciones.forEach(comprobacion => {
          if(comprobacion.tipo != "DATA") {
            result.push(self.getComprobacionesDefectos(comprobacion.hijas)); 
          } else if(comprobacion.resultado == "R_3" || comprobacion.resultado == "R_4") {
            result.push(comprobacion); 
          }
        });
      }
      return result.flat();
    },
    esTrabajoMecanico: function(trabajo) {
      var self = this;
      if(!trabajo.tipoTrabajo || trabajo.tipoTrabajo == 'MECANICO') {
        trabajo.tipoTrabajo = 'MECANICO';
        return true;
      } else {
        return false;
      }
    },
    addEmail: function() {
      var self = this,
        formData = self.$refs.rform.formData,
        ordenTaller = formData.ordenTaller ? formData.ordenTaller : {},
        arr = ordenTaller.destinatarios ? ordenTaller.destinatarios : [];
      if(!formData.ordenTaller){
        self.$set(formData, 'ordenTaller', {});
      }
      arr.push("");
      self.$set(formData.ordenTaller, 'destinatarios', arr);
    },
    generarOrdenTaller: function () {
      var self = this,
        app = self.$f7,
        inspeccion = self.inspeccion,
        rform = self.$refs.rform,
        formData = rform && rform.formData ? rform.formData : {};
      // Miramos a ver si hay destinatarios en la orden de taller
      if (formData && formData.ordenTaller) {
        app.preloader.show();
        if(!formData.ordenTaller.camposCabecera) {
          formData.ordenTaller.camposCabecera = {};
        }

        Inspeccion.enviarOrdenTaller({idInspeccion: inspeccion.id,data: formData.ordenTaller}).then(function(res) {
          // Metemos la nueva orden de taller en la lista de ordenes de taller de la inspección.
          self.$set(rform, 'formData', res.dto); 
          if(formData.ordenTaller.destinatarios && formData.ordenTaller.destinatarios.toString().replaceAll(',','').length > 0) {
            app.dialog.alert("Orden de taller enviada a los destinatarios indicados.");
          } else {
            app.dialog.alert("Orden de taller guardada <b>pero no enviada</b>.");
          }
          self.$emit("finished");
          self.$refs.popup.close();
        }).catch(function(error) {
          if (error.message) {
            app.dialog.alert(error.message);
          } else {
            app.dialog.alert(error);
          }
          self.$refs.popup.close();
        }).finally(_ => app.preloader.hide());
      } else {
        app.dialog.alert("No hay orden de taller que enviar.");
      }
    },
    verOrdenTaller: function (event) {
      var self = this;
      var app = self.$f7;

      // {
      //   let nuevaOT = self.inspeccion.ordenesDeTaller.ordenesDeTaller[event.target.value];
      //   self.$set(self.$refs.rformOrdenTaller.formData, 'ordenTaller', nuevaOT);
      // }

      if(self.inspeccion.configuraciones && self.inspeccion.configuraciones.find(c => c.tipoConfiguracion == 'ORDEN_TALLER_CABECERA')) {
        // vamos a recuperar el nombre de la property que contiene las cabeceras
        var nombreProperty = self.inspeccion.configuraciones.find(c => c.tipoConfiguracion == 'ORDEN_TALLER_CABECERA');
        var configuracionCabecera = [];
        if( nombreProperty ) {
          var p = Promise.resolve();
          p = p.then(_ => new Promise(function(resolve, reject) {
            Property.getList({filter: {nombre: nombreProperty.valor} }).then(function(res) {
              configuracionCabecera = res.list[0];
              var camposCabecera = JSON.parse(configuracionCabecera.valor);
              resolve(camposCabecera);
            }).catch((error) => reject(error));
          }));
          p.then((camposCabecera) => {
            var nuevaOT = self.inspeccion.ordenesDeTaller[event.target.value];
            nuevaOT.camposCabeceraLista = camposCabecera;
            self.$set(self.$refs.rform.formData, 'ordenTaller', nuevaOT); 
          }).catch(function(error) {
            if (error.message) {
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
            self.$refs.popup.close();
          });
        }
      }
    },
  },
  mounted: function(){
    const self = this;
    Departamento.getCombo().then(res => {
      self.departamentos = res.list
    });
    console.log ("inspeccion de la orden de trabajo", self.inspeccion);
  }
};
</script>