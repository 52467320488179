import DatabaseModel from './DatabaseModel.js';

var Coche = Object.create(DatabaseModel);

Coche.model = 'coche';

Coche.getFirstWithInspectionType = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + self.prefix + self.model + '/getFirstWithInspectionType/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }

        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });

    });
}

export default Coche;