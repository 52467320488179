<template>
  <f7-page :page-content="false" @page:mounted="onPageMounted">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('inspecciones.inspecciones.certificaciones')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Inspeccion"
        tableCode="INSPECCIONES"
        filterPosition="PANEL"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['codigo','estado.estado','inspector','situacion.departamento','situacion.fechaPrevista','historicoEstados', 'historicoSituaciones', 'ordenesDeTaller', 'comprobaciones', 'documentos', 'controlCalidad.calificacion', 'historicoControlesCalidad.calificacion', 'controlCalidadRealizado', 'controlesConDefecto', 'cliente._id', 'htmlControlCalidad']"
        :massiveActions="true"
        :funcShowCheck="()=>false"
        :controlsWidth="175"
        :searchOnInit="true"
      >
        <template v-slot:optionsColumn="{item}">
          <f7-link icon-f7="eye" color="green" :href="`/inspecciones/view/${item.id}/`" v-tooltip="'Ver certificación'"></f7-link>
          <f7-link 
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'INSPECTOR', 'RESPONSABLE']}"
            v-if="item.estado.estado == 'PLANIFICADO'" 
            icon-f7="arrow_turn_right_down" 
            color="orange" 
            v-tooltip="'Vehículo recibido'"
            @click="vehiculoRecibido(item)"
          ></f7-link>
          <f7-link 
            v-roles="['ADMINISTRATIVO','RESPONSABLE']"
            v-if="false && item.estado.estado == 'PERITADO'" 
            icon-f7="wrench_fill" 
            color="orange"
            v-tooltip="$t('inspecciones.inspeccion_form.enviarATaller')"
            @click="enviarATaller(item)"
          ></f7-link>
          <f7-link 
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO','RESPONSABLE']}"
            icon-f7="gear_alt_fill" 
            color="red"
            v-tooltip="'Forzar cambio de estado'"
            @click="forzarCambioEstado(item)"
          ></f7-link>
          <f7-link 
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'RESPONSABLE', 'TECNICO']}"
            v-if="preparadoParaEstimar(item)" 
            icon-f7="calendar" 
            color="orange"
            v-tooltip="'Estimar fecha'"
            @click="estimarFecha(item)"
          ></f7-link>
          <f7-link 
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'RESPONSABLE', 'TECNICO']}"
            v-if="preparadoParaFinalizarTareaTaller(item)" 
            icon-f7="calendar_badge_plus" 
            color="orange"
            v-tooltip="'Modificar fecha estimada'"
            @click="modificarFechaEstimada(item)"
          ></f7-link>
          <f7-link 
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'RESPONSABLE', 'TECNICO']}"
            v-if="preparadoParaFinalizarTareaTaller(item)" 
            icon-f7="arrowtriangle_right_fill" 
            color="orange"
            v-tooltip="'Terminar tarea'"
            @click="terminarTarea(item)"
          ></f7-link>
          <f7-link 
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'RESPONSABLE']}"
            v-if="preparadoParaControlCalidad(item)"
            icon-f7="checkmark_alt_circle" 
            :color="colorControlCalidad(item)"
            v-tooltip="'Control Calidad'"
            @click="controlCalidad(item)"
          ></f7-link>
          <f7-link 
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'RESPONSABLE', 'TECNICO']}"
            icon-f7="arrow_counterclockwise" 
            color="green"
            v-tooltip="'Histórico de estados'"
            @click="verHistorico(item)"
          ></f7-link>
          <f7-link 
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'RESPONSABLE', 'TECNICO']}"
            v-if="tieneOrdenes(item)" 
            icon-f7="arrow_down_doc_fill" 
            color="green"
            v-tooltip="'Descargar ordenes de trabajo'"
            @click="descargarOrdenes(item)"
          ></f7-link>
          <!-- <f7-link icon-f7="trash" color="red" @click="rlist.removeItem(item.id)" title="Borra certificación"></f7-link> -->
          <f7-link v-if="item.formato && item.formato.nombreCertificado && (item.calificacion == 'BUENO' || item.calificacion == 'EXCELENTE')" icon-f7="cloud_download_fill" color="green" v-tooltip="'Descargar certificado'"
            @click.prevent="downloadCertificado(item)"
          ></f7-link>
        </template>
      </r-dynamic-list>
      <!-- rList Table CARD-->
    </f7-page-content>
    <ModificarEstado ref="modificarEstado" :inspeccion="inspeccion" @finished="reloadList"/>
    <OrdenTrabajo ref="ordenTrabajo" :inspeccion="inspeccion" @finished="reloadList"/>
    <EstimarTarea ref="estimarTarea" :inspeccion="inspeccion" @finished="reloadList"/>
    <ModificarEstimacionTarea ref="modificarEstimacionTarea" :inspeccion="inspeccion" @finished="reloadList"/>
    <TerminarTarea ref="terminarTarea" :inspeccion="inspeccion" @finished="reloadList"/>
    <ControlCalidad ref="controlCalidad" :inspeccion="inspeccion" @finished="reloadList"/>
    <HistoricoEstadosPopup ref="historicoEstados" :inspeccion="inspeccion"/>
    <f7-popup ref="popupCambioEstado" class="popup-cambio-estado" @popup:closed="$refs.rformCambioEstado.clear();" swipe-to-close>
      <f7-page>
        <f7-navbar title="Cambiar Estado de Inspeccion">
          <f7-nav-right>
            <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
      <r-form
        ref="rformCambioEstado"
        v-slot="{rform}"
        :itemId="inspeccion?inspeccion.id:null"
        :readonly="false"
        :dbAdapter="Inspeccion"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <f7-col width="100" medium="100">
                  <r-input
                    floating-label
                    :label="$t('inspecciones.inspeccion_form.estado')"
                    :form="rform"
                    type="select"
                    name="estado"
                  >
                    <option value></option>
                    <option value="VEHICULO_PRESENTE" v-if="inspeccion && inspeccion.estado && inspeccion.estado.estado == 'PLANIFICADO'">Vehículo presente</option>
                    <option value="EN_TALLER" v-if="inspeccion && inspeccion.estado && inspeccion.estado.estado == 'VEHICULO_PRESENTE'">En taller</option>
                  </r-input>
                  <r-input
                      floating-label
                      label="Comentario"
                      :form="rform"
                      type="textarea"
                      name="comentario"
                    ></r-input>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
      </r-form>
      <f7-button
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="cambiarEstado"
        ></f7-button>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rList from "./../../components/rList.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rPanel from "./../../components/rPanel.vue";
import EstimarTarea from "./components/EstimarTarea.vue";
import ModificarEstimacionTarea from "./components/ModificarEstimacionTarea";
import TerminarTarea from "./components/TerminarTarea.vue";
import ModificarEstado from "./components/ModificarEstado.vue";
import OrdenTrabajo from "./components/OrdenTrabajo.vue";
import ControlCalidad from "./components/ControlCalidad.vue";
import HistoricoEstadosPopup from "./components/HistoricoEstadosPopup.vue";
import Inspeccion from "./../../js/db/Inspeccion.js";
import Inspector from "./../../js/db/Inspector.js";
import Cliente from "./../../js/db/Cliente.js";
import TipoInspeccion from "./../../js/db/TipoInspeccion.js";
import rDynamicList from "./../../components/rDynamicList.vue";

export default {
  components: {
    rList,
    rPanel,
    rForm,
    rInput,
    rTimepicker,
    rAutocomplete,
    rDynamicList,
    EstimarTarea,
    ModificarEstimacionTarea,
    TerminarTarea,
    ModificarEstado,
    HistoricoEstadosPopup,
    OrdenTrabajo,
    ControlCalidad
  },
  data() {
    return {
      Inspeccion,
      Inspector,
      Cliente,
      TipoInspeccion,
      inspeccion: {},
      configuraciones: []
    };
  },
  computed: {},
  methods: {
    onPageMounted: function (e) {
      const self = this;
      if (window.location.href.indexOf("?reloadTable") > -1) {
          window.location.href = window.location.href.split('?')[0];
          self.$refs.rlist.reload();
      }
    },
    vehiculoRecibido(item){
      var self = this,
          app = self.$f7,
          data = {estado: 'VEHICULO_PRESENTE'};
      self.inspeccion = item;
      app.dialog.confirm(
        "Se marcará como recibido el vehículo con matrícula " + item.lineaPedido.coche.matricula + ".",
        "Vehículo recibido",
        function() {
          
          Cliente.get({itemId: self.inspeccion.cliente.id}).then(res => {
            console.log("cliente", res); 
            // si el cliente tiene configurado conectarConSIMECAR, se debe preguntar si se desea solicitar cita
            if(res && res.conectarConSIMECAR) {
              app.dialog.create(
                {
                  title: "¿Desea solicitar cita para inspección a SIMECAR?" ,
                  text: "Solicitar Cita",
                  buttons: [
                    {
                      text: 'Cancelar',
                      color: 'gray',
                      onClick: function(dialog) {}
                    },
                    {
                      text: 'No',
                      color: 'red',
                      onClick: function(dialog) {
                        data.solicitarCita = false;
                        self.ejecutarCambioEstado(data);
                      }
                    },
                    {
                      text: 'Si',
                      color: 'blue',
                      onClick: function(dialog) {
                        data.solicitarCita = true;
                        self.ejecutarCambioEstado(data);
                      }
                    }
                  ]
                }
              ).open();
            }
          });
        }
      );
    },
    ejecutarCambioEstado: function(data) {
      var self = this,
          app  = self.$f7;
      app.preloader.show();
      Inspeccion.cambioEstado(self.inspeccion.id, data).then(function(res) {
        if (res) {
          app.toast
            .create({
              icon: '<i class="f7-icons">checkmark</i>',
              text: 'Vehículo marcado como recibido',
              position: "center",
              destroyOnClose: true,
              closeTimeout: 2000
            })
            .open();
            self.$refs.rformCambioEstado.clear();
            if (self.$refs.popupCambioEstado) {
              self.$refs.popupCambioEstado.close(true);
            }
            self.$refs.rlist.reload();
        }
      }).catch(function(error){
        app.dialog.alert(error.message);
      }).finally( _ => app.preloader.hide());
    },
    preparadoParaEstimar: function(item) {
      const self = this;
      return self.$refs.estimarTarea && self.$refs.estimarTarea.preparado(item);
    },
    preparadoParaFinalizarTareaTaller: function(item) {
      const self = this;
      return self.$refs.terminarTarea && self.$refs.terminarTarea.preparado(item);
    },
    preparadoParaTratar360: function(item) {

    },
    preparadoParaVerificar: function(item) {
      
    },
    preparadoParaControlCalidad: function(item) {
      const self = this;
      return self.$refs.controlCalidad && self.$refs.controlCalidad.preparado(item) && item.controlCalidad && item.controlCalidad.calificacion && (item.controlCalidad.calificacion == "EXCELENTE" || item.controlCalidad.calificacion == "BUENO");
    },
    colorControlCalidad: function(item) {
      const self = this;
      return item.controlCalidadRealizado ? 'green' : 'orange';
    },
    controlCalidad: function(item){
      var self = this;
      self.inspeccion = item;
      self.$refs.controlCalidad.openPopup(self.inspeccion);
    },
    tieneOrdenes: function(item){
      return item.estado.estado == "EN_TALLER";
    },
    descargarOrdenes: function(item){
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Inspeccion.downloadOrdenes(item).finally(_=>app.preloader.hide());
    },
    forzarCambioEstado: function(item){
      var self = this;
      self.inspeccion = item;
      self.$refs.modificarEstado.cambiarEstado();
    },
    enviarATaller: function(item){
      var self = this;
      self.inspeccion = item;
      self.$refs.ordenTrabajo.openPopup(item);
    },
    estimarFecha: function(item) {
      var self = this;
      self.inspeccion = item;
      self.$refs.estimarTarea.openPopup();
    },
    modificarFechaEstimada: function(item) {
      var self = this;
      self.inspeccion = item;
      self.$refs.modificarEstimacionTarea.openPopup();
    },
    terminarTarea: function(item) {
      var self = this,
        campos = (item && item.situacion && item.situacion.jsoncamposPersonalizadosDepartamento)
          ? JSON.parse(item.situacion.jsoncamposPersonalizadosDepartamento) : [];
      self.inspeccion = item;
      self.$set(self.inspeccion.situacion, "camposPersonalizados", {});
      self.$set(self.inspeccion.situacion, "camposPersonalizadosArr", campos);
      self.$refs.terminarTarea.openPopup();
    },
    verHistorico: function(item) {
      var self = this,
        campos = (item && item.situacion && item.situacion.jsoncamposPersonalizadosDepartamento)
          ? JSON.parse(item.situacion.jsoncamposPersonalizadosDepartamento) : [];
      console.log(item);
      self.inspeccion = item;
      self.$refs.historicoEstados.openPopup();
    },
    cambiarEstado: function(e) {
      var self = this;
      var app = self.$f7;
      var data = self.$refs.rformCambioEstado.formData;
      app.dialog.confirm(
        "¿Esta seguro de querer cambiar el estado de la certificación " + self.inspeccion.codigo + "?",
        "Cambio de estado",
        function() {
          app.preloader.show();
          Inspeccion.cambioEstado(self.inspeccion.id, data).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: 'Cambio de estado realizado',
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
                self.$refs.rformCambioEstado.clear();
                if (self.$refs.popupCambioEstado) {
                  self.$refs.popupCambioEstado.close(true);
                }
                self.$refs.rlist.refresh();
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          }).finally( _ => app.preloader.hide());
        }
      );
    },
    submitFilter: function(rlist, event, rpanel) {
      var self = this;
      if (!self.$refs.incluirConcesionarios || !self.$refs.incluirConcesionarios.checked) {
        event.clienteId = event.keyword;
        delete event.keyword;
      }
      rlist.setFilter(event); 
      rpanel.close();
    },
    reloadList: function(){
      const self = this;
      self.inspeccion = null;
      if (self.$refs.rlist) {
        self.$refs.rlist.reload()
      }
    },
    downloadCertificado: function (inspeccion){
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Inspeccion.downloadCertificado(inspeccion).finally(_=>app.preloader.hide());
    },
  },
  // mounted: function() {
  //   var self = this;
  //   var $ = self.$$;
  //   // Miramos si le hemos pasado un filtro
  //   var filter = self.$f7route.url.split('?')[1];
  //   console.log("filter",filter);
  //   if (filter) {
  //     eval(filter);
  //   }
  //   console.log("filter",filter);
  //   self.$refs.rlist.setFilter(filter);
  //   self.submitFilter();
  // }
};
</script>