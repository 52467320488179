
<template>
  <f7-popup ref="popup" v-if="popupAbierto" :opened="true" class="popup-modificar-estimacion" @popup:closed="popupAbierto = false; " swipe-to-close>
    <f7-page>
      <f7-navbar title="Modificar estimación de tarea">
        <f7-nav-right>
          <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
        </f7-nav-right>
      </f7-navbar>
    <f7-card
      class="elevation-3 padding-vertical padding-right"
    >
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="inspeccion?inspeccion.id:null"
      :readonly="false"
      :initialData="inspeccion ? inspeccion : null"
      :defaultData="inspeccion ? inspeccion : null"
      :beforeSave="beforeSave"
      @loadItem="loadItem"
      @save="onSave"
    >
      <f7-card-content>
        <f7-list class="list-form">
          <ul>
            <f7-row>
              <f7-col width="100" medium="100">
                <r-input
                  class="col"
                  floating-label
                  :label="$t('inspecciones.inspeccion_form.fecha_entrada')"
                  :form="rform"
                  type="date"
                  name="situacion.fechaEntrada"
                ></r-input>
                <r-input
                  class="col"
                  floating-label
                  :label="$t('inspecciones.inspeccion_form.fechaPrevistaTarea')"
                  :form="rform"
                  type="date"
                  name="situacion.fechaPrevistaAux"
                ></r-input>
                <r-input
                  class="col"
                  floating-label
                  :label="$t('inspecciones.inspeccion_form.motivo')"
                  :form="rform"
                  name="situacion.motivo"
                ></r-input>
              </f7-col>
            </f7-row>
          </ul>
        </f7-list>
      </f7-card-content>
    </r-form>
    <f7-button
        fill
        icon-f7="floppy_disk"
        text="Guardar"
        class="display-flex margin-left-half"
        @click="$refs.rform.saveItem()"
      ></f7-button>
    </f7-card>
      <!-- </div> -->
    </f7-page>
  </f7-popup>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rForm from "../../../components/rForm.vue";
import rInput from "../../../components/rInput.vue";
import Inspeccion from "../../../js/db/Inspeccion.js";
import Utils from "../../../js/Utils.js";

export default {
  components: {
    rForm,
    rInput
  },
  data() {
    return {
      Inspeccion,
      Utils,
      popupAbierto: false
    };
  },
  props:{
    inspeccion:{
      type: Object
    }
  },
  computed: {},
  methods: {
    loadItem: function(data) {
      const self = this;
      if (self.$refs.rform && self.$refs.rform.formData) {
        self.$set(self.$refs.rform.formData.situacion, "fechaPrevistaAux", self.formatDate(data.situacion.fechaCorregida || data.situacion.fechaPrevista,"YYYY-MM-DD") );
        self.$set(self.$refs.rform.formData.situacion, "fechaEntrada", self.formatDate(data.situacion.fechaEntrada,"YYYY-MM-DD") );
        self.$set(self.$refs.rform.formData.situacion, "motivo", "" );
      }
    },
    formatDate: function(date) {
      const self = this;
      return new Date(date).toISOString().slice(0, 10);
    },
    openPopup: function() {
      const self = this;
      self.popupAbierto = true;
    },
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      if(data.situacion && !data.situacion.motivo){
        app.toast
          .create({
            text: "El campo motivo es obligatorio al modificar la estimación de una tarea.",
            position: "center",
            closeTimeout: 3000,
            icon: '<i class="f7-icons">exclamationmark</i>'
          })
          .open();
        self.$emit("finished");
        reject();
      } else {
        data.situacion.fechaCorregida = data.situacion.fechaPrevistaAux;
        resolve(data);
      }
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      Inspeccion.editSituacion(res).then(res => {
        self.inspeccion = null;
        // if (self.$refs.popup) {
        //   self.$refs.popup.close();
        // }
        if (self.$refs.rform) {
          self.$refs.rform.clear();
        }
        app.toast.create({
          text: self.$t("inspecciones.inspeccion_form.inspeccion_guardada"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        }).open();
        self.$emit("finished");
      });
      
      if (self.$refs.popup) {
          self.$refs.popup.close();
        }
    }
  }
};
</script>