<template>
  <f7-popup 
    v-if="popupAbierto" :opened="true" @popup:closed="popupAbierto = false"
    class="popupHistoricoEstados"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>{{$t('inspecciones.inspeccion_form.cerrar')}}</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{$t('inspecciones.inspeccion_form.historicoEstados')}}
        </f7-nav-title>
      </f7-navbar>
      <template>
        <HistoricoEstados :inspeccion="inspeccion"/>
      </template>
    </f7-page>
  </f7-popup>
</template>
<style scoped>
  .popupHistoricoEstados {
    width: 1000px;
    margin-left: -440px;
  }
</style>
<script>
import rForm from "../../../components/rForm.vue";
import rInput from "../../../components/rInput.vue";
import DateUtils from "./../../../js/DateUtils.js";
import HistoricoEstados from "./HistoricoEstados.vue";

export default {
  components: {
    rForm,
    rInput,
    HistoricoEstados
  },
  data() {
    return {
      popupAbierto: false,
      DateUtils
    };
  },
  props:{
    inspeccion:{
      type: Object
    }
  },
  computed: {},
  methods: {
    openPopup: function() {
      const self = this;
      self.popupAbierto = true;
      // console.log(self.inspeccion);
    },
  }
};
</script>