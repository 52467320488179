<template>
  <div id="svg-tree" ref="svg-tree"></div>
</template>

<script>
import ApexTree from 'apextree'

export default {
  data() {
    return {
      ApexTree,
      graph: null
    };
  },
  props: {
    datos: {
      type: Object,
      required:true,
      default() {
        return {
          name: 'root',
          children: [
            {
              name: 'child1',
              children: [
                {
                  name: 'child1.1',
                  children: [
                    {
                      name: 'child1.1.1',
                    },
                    {
                      name: 'child1.1.2',
                    },
                  ],
                },
                {
                  name: 'child1.2',
                },
              ],
            },
            {
              name: 'child2',
              children: [
                {
                  name: 'child2.1',
                },
                {
                  name: 'child2.2',
                },
              ],
            },
          ],
        };
      }
    },
    options: {
      type: Object,
      default() {
        return {
          width: 700,
          height: 700,
          nodeWidth: 120,
          nodeHeight: 80,
          childrenSpacing: 100,
          siblingSpacing: 30,
          direction: 'top',
          canvasStyle: 'border: 1px solid black;background: #f6f6f6;',
        };
      }
    },
  },
  methods: {
    renderTree: function() {
      var self = this;
      // const tree = new ApexTree(document.getElementById('svg-tree'), self.options);
      const treeContainer = self.$refs['svg-tree'];
      treeContainer.innerHTML = '';
      const tree = new ApexTree(treeContainer, self.options);
      if (self.datos && self.datos.data) {
        self.$set(self, 'graph', tree.render(self.datos));
      }
    }
  },
  watch: {
    datos: function(val) {
      var self = this;
      self.renderTree();
    }
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    self.renderTree();
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
};
</script>
<style>
.rpanel-inner {
  display: block;
  /*transform: translate3d(0, 0, 0) !important;*/

  z-index: 1000;
  box-sizing: border-box;
  position: absolute;
  top: calc(0px + 0px);
  top: calc(var(--f7-statusbar-height) + var(--f7-appbar-app-offset, 0px));
  /*height: calc(100% - 0px - 0px);
  height: calc(
    100% - var(--f7-statusbar-height) - var(--f7-appbar-app-offset, 0px)
  );*/
  height: 100%;
  transform: translate3d(0, 0, 0);
  width: 260px;
  width: var(--f7-panel-width);
  background-color: #fff;
  background-color: var(--f7-panel-bg-color);
  overflow: visible;
  will-change: transform;
  transition-property: transform;
  border-left: 1px solid #ddd;
  overflow: auto;
}
.rpanel-inner,
.rpanel-inner::after {
  transition-duration: var(--f7-panel-transition-duration);
}

.rpanel-inner.rpanel-right {
  right: 0;
  top: 0;
  width: var(--f7-panel-width);
  width: var(--f7-panel-right-width, var(--f7-panel-width));
}

.rpanel-inner.rpanel-right {
  transform: translate3d(100%, 0, 0);
}
.rpanel-inner.rpanel-right.rpanel-active {
  transform: translate3d(0, 0, 0);
  display: block;
  box-shadow: 0px 0px 10px #aaa;
}

.page-with-panel-inner > .page-content {
  overflow: hidden;
}
.content-with-panel-inner {
  overflow-x: hidden !important;
}

.content-with-panel-inner.enough-width > *:not(.rpanel-inner) {
  margin-right: var(--f7-panel-right-width, var(--f7-panel-width)) !important;
}
.content-with-panel-inner.enough-width > .rpanel-inner {
  display: block;
  transform: translate3d(0, 0, 0) !important;
}
</style>