import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';

var Pedido = Object.create(DatabaseModel);

Pedido.model = 'pedido';

Pedido.delete = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server + self.prefix + self.model + '/' + params.itemId + '/cancel',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Pedido.downloadPlantilla = function() {
    var self = this;
    return self.download(
        self.server + self.prefix + self.model + '/plantilla',
        'Plantilla-ImportarLineas.xlsx');
};

Pedido.importalineas = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server + self.prefix + self.model + '/importalineas',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};

export default Pedido;