<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title sliding>{{$t('facturas.generar')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Generar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Factura"
      :defaultData="{fechaHasta:new Date(), fechaEmision:new Date(), fechaVencimiento:new Date((new Date()).getTime() + 30*24*60*60*1000)}"
    >
      <f7-row class="padding-vertical-half padding-right">
        <f7-col width="100" medium="75">
        </f7-col>
      </f7-row>
      <f7-block-title>
        Factura
      </f7-block-title>
      <f7-card class="padding-vertical-half padding-right">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <label class="label-group">{{$t('facturas.que_facturar')}}</label>
                <f7-row>
                  <r-autocomplete
                    ref="cliente"
                    class="col-75"
                    :dbAdapter="Cliente"
                    floating-label
                    :label="$t('facturas.cliente')"
                    :form="rform"
                    name="cliente"
                    nameId="clienteId"
                    idProp="id"
                    searchProp="nombre"
                    labelProp="nombre"
                    :shownFields="['tipoCliente']"
                    :source="function(self) {
                      return function(query, render) {
                        var results = [];
                        for (var i = 0; i < self.items.length; i++) {
                          if (($refs.todosClientes.checked || self.items[i].tipoCliente == 'MARCA') && self.items[i].nombre.toLowerCase().includes(query.toLowerCase())) {
                            self.items[i]['txtValue'] = self.items[i][self.searchProp];
                            results.push(self.items[i]);
                          }
                        }
                        render(results);
                      };
                    }" 
                  ></r-autocomplete>
                    <!-- idProp="id"
                    -->
                  <label class="margin-top"><input class="col-25" type="checkbox" ref="todosClientes"/> Ver todos los clientes</label>
                </f7-row>
                <r-input
                  class="col-50"
                  floating-label
                  :label="$t('facturas.facturar_hasta_dia')"
                  :form="rform"
                  type="datepicker"
                  name="fechaHasta"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="100" medium="50">
              <ul>
                <label class="label-group">{{$t('facturas.a_quien_facturar')}}</label>
                <r-input
                  floating-label
                  :label="$t('facturas.comoFacturar')"
                  :form="rform"
                  type="select"
                  name="formaFacturacion"
                >
                  <option selected value="A_CADA_UNO_LO_SUYO">Generar facturas diferentes para cada concesionario / Marca</option>
                  <option selected value="SOLO_LO_MIO">Facturar solo el cliente seleccionado a su nombre</option>
                  <option value="TODO_A_LA_MARCA">Factura a nombre de la marca</option>
                  <option value="TODO_AL_SELECCIONADO">Facturar a nombre del cliente seleccionado (Él mismo e Hijos)</option>
                </r-input>
                <r-input
                  class="col-50"
                  floating-label
                  :label="$t('facturas.fecha_emision')"
                  :form="rform"
                  type="datepicker"
                  name="fechaEmision"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  class="col-50"
                  floating-label
                  :label="$t('facturas.fecha_vencimiento')"
                  :form="rform"
                  type="datepicker"
                  name="fechaVencimiento"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <!-- <r-input
                  floating-label
                  :label="$t('facturas.envio_email_automatico')"
                  :form="rform"
                  type="select"
                  name="envioAutomatico"
                >
                  <option selected value="false">No</option>
                  <option value="true">Sí</option>
                </r-input> -->
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<style scoped>
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Factura from "./../../js/db/Factura.js";
import Cliente from "./../../js/db/Cliente.js";

export default {
  components: {
    rForm,
    rInput,
    rAutocomplete,
  },
  data: function() {
    var self = this;
    return {
      Factura,
      Cliente,
      readonly: !this.editable
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("facturas.facturas_generadas"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
      app.views.current.router.navigate("/facturas/");
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>