<template>
  
  <div class="list list-form no-hairlines">
    <div 
      v-if="!comprobacion.tipo || comprobacion.tipo == 'DATA'" 
      class="row comprobacion-data" 
      :style="'margin-left: ' + ((nivel ? nivel : 0) * 10) + 'px'">
        <span class="col-100 medium-30 comprobacion-descripcion">
          {{comprobacion.codigo}} - {{comprobacion.nombre}}
        </span>
        
        <template v-if="!desdeFormato">
          <r-input
            v-if="comprobacion.conValor == true"
            class="col-100 medium-40"
            floating-label
            label="Valor"
            :form="form"
            type="text"
            :name="name + '.valor'"
          >
          </r-input>
          <div v-else class="col-100 medium-40"></div>
          <r-input
            class="col-85 medium-15"
            floating-label
            label="Resultado"
            :form="form"
            type="select"
            :name="name + '.resultado'"
          >
            <option selected disabled></option>
            <option v-for="(item,index) in resultadosValidos"
                :key="name + '-resultadoValido-'+index" :value="item.valor">{{item.nombre}}</option>
            <!-- <option value="R_0">N/A</option>
            <option value="R_1">BUENO</option>
            <option value="R_2">EXCELENTE</option>
            <option value="R_3">DESFAVORABLE</option> -->
          </r-input>
          <a class="col-5" @click="popupInstruccionesOpened = true">
            <i class="f7-icons"
              :class="{'text-color-primary comprobacion-icono':comprobacion.instrucciones, 'text-color-gray comprobacion-icono':!comprobacion.instrucciones}"
            >info_circle_fill</i>
          </a>
          <!-- <f7-icon 
            f7="info_circle_fill" 
            :color="comprobacion.instrucciones && comprobacion.instrucciones != '' ? 'blue' : 'gray'"
            v-tooltip="comprobacion.instrucciones"
            class="col-5 comprobacion-icono"
          ></f7-icon> -->
          <a class="col-5" @click="popupComentariosOpened = true">
            <i class="material-icons"
              :class="{'text-color-primary comprobacion-icono':comprobacion.comentarios, 'text-color-gray comprobacion-icono':!comprobacion.comentarios}"
            >message</i>
          </a>
          <!-- <a class="col-5" @click="popupConflictosOpened = true">
            <i class="material-icons"
              :class="{'text-color-red comprobacion-icono':comprobacion.conflicto, 'text-color-gray comprobacion-icono':!comprobacion.conflicto}"
            >warning</i>
          </a> -->
        </template>
        <!-- <r-autocomplete-m2m
          :dbAdapter="GrupoFuncional"
          class="col-100 medium-60"
          floating-label
          label="Grupos funcionales"
          :form="form"
          :name="name + '.gruposFuncionales'"
          prefixProp="codigo"
          labelProp="nombre"
          type="text"
          idProp="codigo"
          :lineBreak="false"
        ></r-autocomplete-m2m> -->
    </div>
    <div 
      v-else-if="comprobacion.tipo && comprobacion.tipo == 'SUBGROUP'" 
      class="row comprobacion-subgroup" :style="'margin-left: ' + ((nivel ? nivel : 0) * 10) + 'px'">
        <span class="col-100 medium-30 comprobacion-descripcion">
          {{comprobacion.codigo}} - {{comprobacion.nombre}}
        </span>
        <f7-icon 
          class="col-10"
          v-if="comprobacion.instrucciones && comprobacion.instrucciones != ''" f7="info_circle_fill" color="blue" 
          v-tooltip="comprobacion.instrucciones"
        ></f7-icon>
    </div>
    <div v-else :class="'comprobacion-group-' + (nivel && nivel > 0 ? 'true' : 'false')">
      <f7-card-header>
        <f7-col width="90">
          {{comprobacion.codigo}} - {{comprobacion.nombre}}
        </f7-col>
        <f7-col width="10">
          <f7-icon 
            v-if="comprobacion.instrucciones && comprobacion.instrucciones != ''" f7="info_circle_fill" color="blue" 
            v-tooltip="comprobacion.instrucciones"
          ></f7-icon>
         </f7-col>
      </f7-card-header>
    </div>

    <div
      v-for="(item,index) in comprobacion.hijas"
      :key="name + '-hijas-'+index"
    >
      <r-comprobacion 
        :comprobacion="item" 
        :name="name+'.hijas.'+index" 
        :form="form" 
        :nivel="(nivel ? nivel : 0) + 1"
        :resultadosValidos ="comprobacion.resultadosValidos ? comprobacion.resultadosValidos : resultadosValidos"
        :desdeFormato="desdeFormato"
      ></r-comprobacion>
    </div>

    <div
      v-if="comprobacion.tipo && (comprobacion.tipo == 'GROUP' || comprobacion.tipo == 'SUBGROUP') && comprobacion.conValor == true"
    >
      <r-input
        class="col-100"
        floating-label
        label="Valor"
        :form="form"
        type="textarea"
        :name="name + '.valor'"
      >
      </r-input>
    </div>

    <f7-popup v-if="popupComentariosOpened" :opened="true" @popup:closed="popupComentariosOpened = false">
      <f7-page>
        <f7-navbar title="Comentario">
          <f7-nav-right>
            <f7-link popup-close icon-f7="multiply"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <div class="list no-hairlines no-margin full-height">
          <ul class="full-height">
            <li class="item-content item-input full-height">
              <div class="item-inner full-height">
                <div class="item-input-wrap full-height">
                  <textarea
                    placeholder="Escribe aquí algún comentario..."
                    maxlength="256"
                    class="full-height"
                    :readonly="form.readonly"
                    :value="comprobacion.comentarios"
                    @change="comprobacion.comentarios = $event.target.value"
                  ></textarea>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </f7-page>
    </f7-popup>
    <f7-popup v-if="popupInstruccionesOpened" :opened="true" @popup:closed="popupInstruccionesOpened = false">
      <f7-page>
        <f7-navbar title="Instrucciones">
          <f7-nav-right>
            <f7-link popup-close icon-f7="multiply"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <div class="list no-hairlines no-margin full-height">
          <ul class="full-height">
            <li class="item-content item-input full-height">
              <div class="item-inner full-height">
                <div class="item-input-wrap full-height">
                  <textarea
                    maxlength="256"
                    class="full-height"
                    :readonly="true"
                    :value="comprobacion.instrucciones"
                    @change="comprobacion.instrucciones = $event.target.value"
                  ></textarea>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </f7-page>
    </f7-popup>
    <f7-popup v-if="popupConflictosOpened" :opened="true" @popup:closed="popupConflictosOpened = false">
      <f7-page>
        <f7-navbar title="Incidencia">
          <f7-nav-right>
            <f7-link popup-close icon-f7="multiply"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-button
          fill
          icon-f7="eye"
          text="Ver Incidencia"
          class="display-flex margin-left margin-right margin-top"
          popup-close
          :href="'/conflictos/view/' + comprobacion.conflicto.id + '/'"
        ></f7-button>
        <f7-list class="list-form">
          <label class="margin-left">{{$t('conflictos.conflicto_form.estandar')}} - {{$t('conflictos.conflicto_form.subestandar')}}</label>
          <f7-card><f7-card-content>{{comprobacion.conflicto.estandar}} - {{comprobacion.conflicto.subEstandar}}</f7-card-content></f7-card>
          <label class="margin-left">{{$t('inspecciones.inspeccion_form.resolucion')}}</label>
          <f7-card><f7-card-content v-html="getResolucionBadge(comprobacion.conflicto.resolucion)"></f7-card-content></f7-card>
          <label class="margin-left">{{$t('conflictos.conflicto_form.tipo_conflicto')}}</label>
          <f7-card><f7-card-content v-html="getTipoConflictoBadge(comprobacion.conflicto.tipoConflicto)"></f7-card-content></f7-card>
          <label class="margin-left">{{$t('inspecciones.inspeccion_form.comentario_inspector')}}</label>
          <f7-card><f7-card-content>{{comprobacion.conflicto.comentario}}</f7-card-content></f7-card>
          <label class="margin-left">{{$t('inspecciones.inspeccion_form.comentario_resolucion')}}</label>
          <f7-card><f7-card-content>{{comprobacion.conflicto.comentarioResolucion}}</f7-card-content></f7-card>
        </f7-list>
      </f7-page>
    </f7-popup>

  </div>

</template>
<style>
  div.list{
    list-style: none;
  }
  span.comprobacion-descripcion {
    margin-top: 20px;
    margin-left: 20px;
  }
  i.comprobacion-icono {
    margin-top: 20px;
  }
  div.comprobacion-subgroup {
    font-weight: bold;
  }
  div.comprobacion-group-false .card-header {
    font-size: 20px;
  }
  div.comprobacion-group-true .card-header {
    margin-left: 20px;
  }
</style>
<script>

import rInput from "./rInput.vue";
import rAutocompleteM2m from "./rAutocompleteM2m.vue";
import GrupoFuncional from "../js/db/GrupoFuncional";
export default {
  name: "r-comprobacion",
  components: {
    rInput,
    rAutocompleteM2m
  },
  data() {
    return {
      self: this,
      GrupoFuncional,
      popupComentariosOpened: false,
      popupInstruccionesOpened: false,
      popupConflictosOpened: false
    };
  },
  methods: {
    getTipoConflictoBadge: function(tipoConflicto) {
      switch (tipoConflicto) {
        case "RESUELTO_CAMPO":
          var texto = "Resuelto en campo";
          var color = "green";
          break;
        case "ENVIADO_MARCA":
          var texto = "Enviado marca";
          var color = "orange";
          break;
        default:
          var texto = tipoConflicto;
          var color = "gray";
      }
      return `<span class="badge color-` + color + `">` + texto + `</span>`;
    },
    getResolucionBadge: function(resolucion) {
      switch (resolucion) {
        case "OK":
          var texto = "Positiva";
          var color = "green";
          break;
        case "KO":
          var texto = "Negativa";
          var color = "red";
          break;
        default:
          var texto = "Sin resolver";
          var color = "gray";
      }
      return `<span class="badge color-` + color + `">` + texto + `</span>`;
    }
  },
  props: {
    comprobacion: {
      required: true
    },
    name: {
      required: true,
      type: String
    },
    form: {
      required: true
    },
    nivel: {
      type: Number,
      default: 0
    },
    desdeFormato: {
      type: Boolean,
      default: false
    },
    resultadosValidos: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
  }
};
</script>