import RoutesUtils from './../../js/RoutesUtils.js';
import InspeccionForm from './inspeccion-form.vue';
import Inspecciones from './inspecciones.vue';

var routes = [{
        path: '/inspecciones/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones', ['ADMINISTRATIVO', 'INSPECTOR', 'TECNICO', 'RESPONSABLE', 'VENTAS'], Inspecciones),
        keepAlive: true
    },
    {
        path: '/inspecciones/filter',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones', ['ADMINISTRATIVO', 'INSPECTOR', 'TECNICO', 'RESPONSABLE', 'VENTAS'], Inspecciones),
        keepAlive: false
    },
    // {
    //     path: '/inspecciones/new/',
    //     beforeEnter: RoutesUtils.checkAuthPage,
    //     async: RoutesUtils.checkPermission('inspecciones/new', InspeccionForm)
    // },
    // {
    //     path: '/inspecciones/edit/:id/',
    //     beforeEnter: RoutesUtils.checkAuthPage,
    //     async: RoutesUtils.checkPermission('inspecciones/edit', InspeccionForm)
    // },
    {
        path: '/inspecciones/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones/view', ['ADMINISTRATIVO', 'INSPECTOR', 'TECNICO', 'RESPONSABLE', 'VENTAS'], InspeccionForm, { editable: false })
    },
];
export default routes;