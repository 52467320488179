<template>
  <span class="badge" :style="'color: ' + letterColor + '; background-color: '+ color" v-html="text"></span>
</template>
<style>
span.badge {
  padding: 6px 10px;
}
</style>
<script>
export default {
  data() {
    return {
      self: this
    };
  },
  computed: {
    letterColor: function() {
      let hexcolor = this.color || '#0000ff';
      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
      }

      // Convert to RGB value
      var r = parseInt(hexcolor.substr(0,2),16);
      var g = parseInt(hexcolor.substr(2,2),16);
      var b = parseInt(hexcolor.substr(4,2),16);

      // Get YIQ ratio
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

      // Check contrast
      return (yiq >= 128) ? 'black' : 'white';
    }
  },
  props: {
    color: {
      required: false,
      type: String
    },
    text: {
      required: true,
      type: String
    }
  }
};
</script>