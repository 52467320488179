<template>
  <f7-popup ref="popup" v-if="popupAbierto" :opened="true" class="popup-valorar-arreglos" @popup:closed="popupAbierto = false; " swipe-to-close>
    <f7-page>
      <f7-navbar :title="$t('inspecciones.inspeccion_form.valorar_arreglos')">
        <f7-nav-right>
          <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
        </f7-nav-right>
      </f7-navbar>
    <f7-card
      class="elevation-3 padding-vertical padding-right"
    >
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="inspeccion?inspeccion.id:null"
      :readonly="false"
      :initialData="inspeccion ? inspeccion : null"
      :defaultData="inspeccion ? inspeccion : null"
      :beforeSave="beforeSave"
      @save="onSave"
    >
      <f7-card-content>
        <f7-list class="list-form">
          <ul>
            <f7-row>
              <f7-col width="100" medium="50" >
                <r-input
                  floating-label
                  :label="$t('inspecciones.inspeccion_form.valoracionArreglos.importeChapa')"
                  :form="rform"
                  type="number"
                  step="0.01"
                  :name="'valoracionArreglos.importeChapa'"
                ></r-input>
              </f7-col>
              <f7-col width="100" medium="50" >
                <r-input
                floating-label
                :label="$t('inspecciones.inspeccion_form.valoracionArreglos.importeMecanica')"
                :form="rform"
                type="number"
                step="0.01"
                :name="'valoracionArreglos.importeMecanica'"
                ></r-input>
              </f7-col>
              <f7-col width="0" medium="20">
              </f7-col>
              <f7-col width="100" medium="100">
                <r-files 
                  class="col-100"
                  :form="rform" 
                  :name="'valoracionArreglos.documentos'" 
                  :label="'Documentación de la valoración'" 
                  :documentoAdapter="Documento"
                  :preview="true"
                  :overrideReadonly="true"
                ></r-files>
              </f7-col>
              <f7-col width="100" medium="100">
                <r-input
                  floating-label
                  :label="'Observaciones'"
                  :form="rform"
                  type="textarea"
                  :name="'valoracionArreglos.observaciones'"
                ></r-input>
              </f7-col>
            </f7-row>
          </ul>
          <ul>
            <!-- Fotos con nombre -->
            <div style="width: 100%;">
                      <f7-navbar class="navbar">
                        <f7-block-title>
                          {{$t('inspecciones.inspeccion_form.valoracionArreglos.fotosConNombre')}}
                        </f7-block-title>
                        <f7-link
                          v-if="!rform.readonly"
                          slot="right"
                          @click="nuevaFotoConNombre(inspeccion.valoracionArreglos.fotosConNombre)"
                          icon-f7="plus_app_fill"
                        ></f7-link>
                      </f7-navbar>
                      
                      <f7-card class="elevation-3 padding-vertical-half padding-right">
                        <f7-card-content>
                          <f7-list 
                            v-for="(fotoConNombre,index) in inspeccion.valoracionArreglos.fotosConNombre" 
                            :key="'valoracionArreglos-fotosConNombre-'+index"
                            class="fotoconnombre"
                          >
                            <div >
                              <f7-row>
                                <r-files :form="rform" :name="'valoracionArreglos.fotosConNombre.'+index+'.documentos'" :documentoAdapter="Documento" :isObject="false">
                                  <template v-slot:topbar="{}">
                                    <!-- <f7-button 
                                      v-if="!readonly" 
                                      style="display:inline-block;aling:left;"
                                      slot="right" 
                                      color="primary"
                                      @click="capturarFotoConNombre(fotoConNombre, index)" 
                                      icon-f7="add_a_photo" 
                                      v-tooltip="'Capturar foto'"
                                    ></f7-button> -->
                                  </template>
                                </r-files>
                                <f7-button
                                  v-if="!rform.readonly && fotoConNombre.editable"
                                  small
                                  color="red"
                                  icon-f7="trash" 
                                  v-tooltip="'Eliminar'"
                                  @click="eliminarFotoConNombre(fotoConNombre, index, inspeccion.valoracionArreglos.fotosConNombre)"
                                ></f7-button>
                              </f7-row>
                              <f7-row>
                                <r-input
                                  class="col-85" 
                                  :form="rform"
                                  type="text"
                                  :name="'valoracionArreglos.fotosConNombre.'+index+'.nombre'"
                                  :readonly="!fotoConNombre.editable"
                                ></r-input>
                                <r-input
                                  class="col-20"
                                  :form="rform"
                                  type="check"
                                  :simple="true"
                                  v-tooltip="fotoConNombre.visible?$t('inspecciones.inspeccion_form.visible'):$t('inspecciones.inspeccion_form.oculto')"
                                  :name="'valoracionArreglos.fotosConNombre.'+index+'.visible'"
                                  :key="'valoracionArreglos.fotosConNombre.'+index+'.visible'"
                                ></r-input>
                              </f7-row>

                            </div>
                          </f7-list>
                        </f7-card-content>
                      </f7-card>
                    </div>
                    <!-- Fin de fotos con nombre -->
          </ul>
        </f7-list>
      </f7-card-content>
    </r-form>
    <f7-button
        fill
        icon-f7="floppy_disk"
        text="Guardar"
        class="display-flex margin-left"
        @click="$refs.rform.saveItem()"
      ></f7-button>
    </f7-card>
      <!-- </div> -->
    </f7-page>
  </f7-popup>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rForm from "../../../components/rForm.vue";
import rInput from "../../../components/rInput.vue";
import rFiles from "../../../components/rFiles.vue";
import Inspeccion from "../../../js/db/Inspeccion.js";
import Documento from "../../../js/db/Documento.js";
import Utils from "../../../js/Utils.js";

export default {
  components: {
    rForm,
    rInput,
    rFiles
  },
  data() {
    return {
      Inspeccion,
      Documento,
      Utils,
      popupAbierto: false
    };
  },
  props:{
    inspeccion:{
      type: Object
    }
  },
  computed: {},
  methods: {
    openPopup: function() {
      const self = this;
      self.popupAbierto = true;
    },
    beforeSave: function(data, resolve, reject) {
      resolve(data);
    },
    onSave: function(data) {
      var self = this;
      var app = self.$f7;
      // Vamos a cambiar el estado de la inspección a valorada
      console.log("Guardando inspección", self.inspeccion);
      let valoracionArreglos = data.valoracionArreglos;
      valoracionArreglos.id = self.inspeccion.id;
      Inspeccion.editValoracionArreglos(valoracionArreglos).then(res => {
        self.inspeccion = null;
        if (self.$refs.popup) {
          self.$refs.popup.close();
        }
        if (self.$refs.rform) {
          self.$refs.rform.clear();
        }
        app.toast
          .create({
            text: self.$t("inspecciones.inspeccion_form.inspeccion_guardada"),
            position: "center",
            closeTimeout: 2000,
            icon: '<i class="f7-icons">checkmark_alt</i>'
          })
          .open();
        self.$emit("finished");
      })
    }
  }
};
</script>