import DatabaseModel from './DatabaseModel.js';

var Factura = Object.create(DatabaseModel);

Factura.model = 'factura';

Factura.delete = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server + self.prefix + self.model + '/' + params.itemId + '/cancel',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Factura.send = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server + self.prefix + self.model + '/' + params.itemId + '/send',
            method: 'GET',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Factura.downloadFactura = function(item) {
    var self = this;
    return self.download(
        self.server + self.prefix + self.model + '/' + item.id + '/pdf', 
        'Factura ' + item.numero + '.pdf');
}

export default Factura;