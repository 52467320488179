  <template>
    <f7-list class="table-estados">
      <f7-card class="elevation-0">
        <f7-row no-gap class="list_popup head-estados">
          <span class="col-15">{{$t('inspecciones.inspeccion_form.usuario')}}</span>
          <span class="col-25">{{$t('inspecciones.inspeccion_form.paso_a_estado')}}</span>
          <span class="col-25">{{$t('inspecciones.inspeccion_form.motivo_observaciones')}}</span>
          <span class="col-15">{{$t('inspecciones.inspeccion_form.fecha')}}</span>
          <span class="col-10">{{$t('inspecciones.inspeccion_form.fecha_entrada')}}</span>
          <span class="col-10">{{$t('inspecciones.inspeccion_form.fechaFinPrevisto')}}</span>
        </f7-row>
        <f7-row 
          no-gap
          v-for="(item,index) in getHistoricoEstados()"
          :key="'historicoEstados-'+index"  
          class="list_popup"
        >
          <f7-list-input
            :value="item.username"
            v-tooltip="item.username"
            :readonly="true"
            class="col-15"
            type="text"
          />
          <r-input
            nowrap
            :class="'col-25 badge-estado ' + getColorBordeBadgeEstadoVehiculo(item, index)"
            type="badge"
            name="estado"
            :readonly="true"
            :overrideReadonly="true"
            :text="getTextoBadgeEstadoVehiculo(item)"
            :color="getColorBadgeEstadoVehiculo(item)"
          ></r-input>
          <f7-list-input
            :value="(item.situacion && item.situacion.motivo)?item.situacion.motivo:item.observaciones"
            v-tooltip="(item.situacion && item.situacion.motivo)?item.situacion.motivo:item.observaciones"
            :readonly="true"
            class="col-25"
            type="text"
  
          />
          <f7-list-input
            :value="DateUtils.dateFormat(item.fecha, 'DD/MM/YYYY HH:mm')"
            v-tooltip="DateUtils.dateFormat(item.fecha, 'DD/MM/YYYY HH:mm')"
            :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
            :readonly="true"
            class="col-15"
            type="text"
          />
          <f7-list-input
            :value="(item.situacion && item.situacion.fechaEntrada)?DateUtils.dateFormat(item.situacion.fechaEntrada, 'DD/MM/YYYY'):''"
            v-tooltip="(item.situacion && item.situacion.fechaEntrada)?DateUtils.dateFormat(item.situacion.fechaEntrada, 'DD/MM/YYYY'):''"
            :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
            :readonly="true"
            class="col-10"
            type="text"
          />
          <f7-list-input
            v-if="item.situacion"
            :value="DateUtils.dateFormat(item.situacion.fechaCorregida||item.situacion.fechaPrevista, 'DD/MM/YYYY')"
            v-tooltip="DateUtils.dateFormat(item.situacion.fechaCorregida||item.situacion.fechaPrevista, 'DD/MM/YYYY')"
            :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
            :readonly="true"
            class="col-10"
            type="text"
          />
          <f7-list-input v-else 
            :readonly="true"
            class="col-10"
          />
        </f7-row>
        <f7-row no-gap class="list_popup head-estados">
          <span class="col-100">Tiempos Totales</span>
        </f7-row>
        <f7-row 
          no-gap
          class="list_popup list-totales"
        >
          <r-input
            v-for="(item,index) in getTotalesEstados()"
            :key="'historicoEstados-totales-'+index"  
            nowrap
            class="col-25 badge-estado"
            type="badge"
            name="estado"
            :readonly="true"
            :overrideReadonly="true"
            :text="getTextoBadgeEstadoVehiculo(item)"
            :color="getColorBadgeEstadoVehiculo(item)"
          ></r-input>
        </f7-row>
      </f7-card>
    </f7-list>
  </template>
<style>
  .badge-estado .badge {
    height: auto;
  }
  .badge_border_blue .badge {
    border: 2px solid blue;
  }
  .badge_border_red .badge {
    border: 2px solid red;
  }
  .badge_border_green .badge {
    border: 2px solid green;
  }
</style>
<style scoped>
.head-estados {
  text-align:center;
  font-weight: bold;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 16;
}
.table-estados {
  border: 1px solid grey;
  border-radius: 5px;
  margin-left: 15px;
  margin-right: 15px;
  /* padding: 0 5px 5px 5px; */
}
.list-totales {
  justify-content: flex-start;
}
</style>
<script>
import rForm from "../../../components/rForm.vue";
import rInput from "../../../components/rInput.vue";
import DateUtils from "./../../../js/DateUtils.js";

export default {
  components: {
    rForm,
    rInput
  },
  data() {
    return {
      popupAbierto: false,
      DateUtils
    };
  },
  props:{
    inspeccion:{
      type: Object
    }
  },
  computed: {},
  methods: {
    formatFechaEstado: function(times) {
      let d = Math.trunc((times)/(24*60*60*1000));
      let h = Math.trunc(((times)%(24*60*60*1000))/(60*60*1000));
      let m = Math.trunc((((times)%(24*60*60*1000))%(60*60*1000))/(60*1000));

      let tiempoStr = (d > 0 ? d + "d " :"") + (h > 0 ? h + "h " :"") +  (m > 0 ? m + "min" :"");

      // formato para cuando son muchos días
      if (d > 30) {
        let anyos = Math.trunc(d/365);
        let meses = Math.trunc((d%365)/30);
        let dias = Math.trunc((d%365)%30);

        let textAnyo = anyos == 1 ? " año ": " años ";
        let textMes = meses == 1 ? " mes ": " meses ";
        let textDia = dias == 1 ? " día ": " días" ;

        tiempoStr = (anyos > 0 ? anyos + textAnyo :"") + (meses > 0 ? meses + textMes :"") +  (dias > 0 ? dias + textDia :"");
      }

      if (!tiempoStr) {
        tiempoStr = "0 min"
      }

      tiempoStr = "(" + tiempoStr + ")";
      return tiempoStr;
    },
    getHistoricoEstados: function() {
      const self = this;
      let historico = self.inspeccion && self.inspeccion.historicoCompleto ? self.inspeccion.historicoCompleto : [];
      historico.forEach((estado, idx) => {
        var estadoSiguiente = historico[idx + 1];
        let tiempoStr = "";
        let tiempo = 0;
        if(estado.fecha) {
          let fin = !!estadoSiguiente ? new Date(estadoSiguiente.fecha) : new Date();
          tiempoStr = self.formatFechaEstado(fin - new Date(estado.fecha));
          tiempo = fin - new Date(estado.fecha);
        }
        estado.tiempoStr = tiempoStr;
        estado.tiempo = tiempo;
      });
      return historico;
    },
    getTotalesEstados: function() {
      const self = this;
      let historicos = self.getHistoricoEstados();
      let totales = [];
      historicos.forEach(h => {
        let total = totales.filter(t => t.estado == h.estado)[0];
        if (!total) {
          total = {
            estado: h.estado,
            tiempo: h.tiempo,
            color: h.color       
          }
          totales.push(total);
        } else {
          total.tiempo += h.tiempo;
        }
      });
      totales.forEach(t => {
        let tiempoStr = self.formatFechaEstado(t.tiempo);
        t.tiempoStr = tiempoStr;
      });
      totales.sort((a, b) => {
        if (a.tiempo > b.tiempo) {
          return -1;
        } else if (a.tiempo < b.tiempo) {
          return 1;
        } else {
          return 0;
        }
      });
      return totales;
    },
    getColorBordeBadgeEstadoVehiculo: function(estado, index) {
      var self = this;
      if(estado.estado && estado.estado.indexOf("Taller -") == 0 ) {
        if ( !estado.situacion.fechaEntrada) {
          return "badge_border_blue";
        } else if (estado.situacion.fechaEntrada) {
          // miramos el estado siguiente, si es el mismo, pintamos borde de rojo
          var estadoSiguiente = self.inspeccion.historicoCompleto[index + 1];
          if (!estadoSiguiente || (estadoSiguiente && estadoSiguiente.estado == estado.estado)) {
            return "badge_border_red";
          } else {
            return "badge_border_green";
          }
        }
      } else {
        return "";
      }
    },
    getTextoBadgeEstadoVehiculo: function(estado) {
      var self = this;
      if(estado.estado) {
        switch (estado.estado) {
          // case "CITA_SOLICITADA":
          //   return "Cita solicitada";
          case "PLANIFICADO":
            return "PENDIENTES DE ENTRADA " + estado.tiempoStr;
          case "VEHICULO_PRESENTE":
            return "PRESENTE " + estado.tiempoStr;
          case "PENDIENTE_VALORACION":
            return "VALORACIÓN DE DAÑOS " + estado.tiempoStr;
          case "PENDIENTE_PARTES":
            return "PENDIENTE DE PARTES " + estado.tiempoStr;
          case "PENDIENTE_ACEPTACION":
            return "PENDIENTE ACEPTAR PRESUPUESTO " + estado.tiempoStr;
          case "PERITADO":
            return "PERITADO " + estado.tiempoStr;
          case "EN_TALLER":
            return "EN TALLER " + estado.tiempoStr;
          case "CONTROL_FINAL":
            return "CONTROL CALIDAD " + estado.tiempoStr;
          case "FINALIZADO":
            return "LISTO PARA REESTRENO " + estado.tiempoStr;
          case "VENDIDO":
            return "ENTREGADO A CLIENTE ";
          case "CANCELADA":
            return "CANCELADO ";
          default:
            return estado.estado + " " + estado.tiempoStr;
        }
      } else {
        return "Sin estado";
      }
          
    },
     getColorBadgeEstadoVehiculo: function(estado) {
       if (estado.estado) {
        if (estado.color) {
          return estado.color;
        }
        if (estado.estado.indexOf("Taller -") == 0) {
          return "green";
        }
        switch (estado.estado) {
          case "PLANIFICADO":
            return "yellow";
          case "VEHICULO_PRESENTE":
            return "lime";
          case "PENDIENTE_VALORACION":
            return "blue";
          case "PENDIENTE_PARTES":
            return "blue";
          case "PENDIENTE_ACEPTACION":
            return "blue";
          case "PERITADO":
            return "blue";
          case "EN_TALLER":
            return "green";
          case "CONTROL_FINAL":
            return "orange";
          case "FINALIZADO":
            return "pink";
          case "VENDIDO":
            return "gray";
          case "CANCELADA":
            return "red";
        }
      } else {
        return "gray";
      }
          
    },
  }
};
</script>