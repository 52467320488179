import DatabaseModel from './DatabaseModel.js';

var Dashboard = Object.create(DatabaseModel);

Dashboard.model = 'dashboard';

// Dashboard.inspeccionesRealizadas = function(params) {
//     var self = this;
//     params = params || {};
//     return new Promise(function(resolve, reject) {
//         //var params_ser = '';
//         self.request.promise({
//             url: self.server + self.prefix + self.model + '/inspeccionesRealizadas/',
//             method: 'POST',
//             contentType: 'application/json',
//             dataType: 'json',
//             crossDomain: true,
//             data: params || {}
//         }).then(function({ data, xhr, status }) {
//             if (data.error) {
//                 reject(data);
//             } else {
//                 resolve(data);
//             }
//         }).catch(function({ xhr, status, message }) {
//             try {
//                 reject(JSON.parse(xhr.response).error);
//             } catch (e) {
//                 reject(xhr.response);
//             }
//         });
//     });
// }

Dashboard.diasMediaEstado = function(params) {
    var self = this;
    params = params || {};
    return new Promise(function(resolve, reject) {
        //var params_ser = '';
        self.request.promise({
            url: self.server + self.prefix + self.model + '/diasMediaEstado/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params || {}
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Dashboard.searchControlCalidad = function(params) {
    var self = this;
    params = params || {};
    return new Promise(function(resolve, reject) {
        //var params_ser = '';
        self.request.promise({
            url: self.server + self.prefix + self.model + '/metricaControlCalidad/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params || {}
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

// Dashboard.inspeccionesKO = function(params) {
//     var self = this;
//     params = params || {};
//     return new Promise(function(resolve, reject) {
//         //var params_ser = '';
//         self.request.promise({
//             url: self.server + self.prefix + self.model + '/inspeccionesKO/',
//             method: 'POST',
//             contentType: 'application/json',
//             dataType: 'json',
//             crossDomain: true,
//             data: params || {}
//         }).then(function({ data, xhr, status }) {
//             if (data.error) {
//                 reject(data);
//             } else {
//                 resolve(data);
//             }
//         }).catch(function({ xhr, status, message }) {
//             try {
//                 reject(JSON.parse(xhr.response).error);
//             } catch (e) {
//                 reject(xhr.response);
//             }
//         });
//     });
// }

// Dashboard.comprobacionesKO = function(params) {
//     var self = this;
//     params = params || {};
//     return new Promise(function(resolve, reject) {
//         //var params_ser = '';
//         self.request.promise({
//             url: self.server + self.prefix + self.model + '/comprobacionesKO/',
//             method: 'POST',
//             contentType: 'application/json',
//             dataType: 'json',
//             crossDomain: true,
//             data: params || {}
//         }).then(function({ data, xhr, status }) {
//             if (data.error) {
//                 reject(data);
//             } else {
//                 resolve(data);
//             }
//         }).catch(function({ xhr, status, message }) {
//             try {
//                 reject(JSON.parse(xhr.response).error);
//             } catch (e) {
//                 reject(xhr.response);
//             }
//         });
//     });
// }

// Dashboard.getList = function(params, forceReload) {
//     var self = this;
//     params = params || {}
//     var paramsDefault = {
//         currentPage: (params.page || 1) - 1,
//         perPage: params.limit,
//         sortBy: params.order,
//         sortDesc: params.orderDir == 'DESC' ? true : false,
//         complete: params.complete,
//         projection: params.projection,
//         operation: params.operation
//     };
//     var params_ser = Framework7.utils.serializeObject(paramsDefault);
//     var paramsRequest = {
//         url: self.server + self.prefix + self.model + '/search/?' + params_ser,
//         method: 'POST',
//         data: params.filter || {}
//     };
//     return self.sendRequest(paramsRequest, !forceReload);
// }

export default Dashboard;