<template>
  <f7-popup ref="popup" v-if="popupAbierto" :opened="true" class="popup-terminar-tarea" @popup:closed="popupAbierto = false; " swipe-to-close>
    <f7-page>
      <f7-navbar title="Terminar tarea">
        <f7-nav-right>
          <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
        </f7-nav-right>
      </f7-navbar>
    <f7-card
      class="elevation-3 padding-vertical padding-right"
    >
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="inspeccion?inspeccion.id:null"
      :readonly="false"
      :initialData="inspeccion ? inspeccion : null"
      :defaultData="getDefaultData(inspeccion)"
      @save="onSave"
    >
      <f7-card-content v-if="inspeccion && inspeccion.situacion && inspeccion.situacion.camposPersonalizadosArr && inspeccion.situacion.camposPersonalizadosArr.length">
        <f7-list class="list-form">
          <ul>
            <f7-row>
              <f7-col v-for="(campo,index) in inspeccion.situacion.camposPersonalizadosArr" :key="'situacion-campoPersonalizado-' + index" 
                  width="100" medium="50">
                <r-input
                  floating-label
                  :label="campo.titulo"
                  :form="rform"
                  :name="'situacion.camposPersonalizados.'+campo.nombre"
                ></r-input>
              </f7-col>
            </f7-row>
            <f7-row>
              <f7-col width="100" medium="100">
                <r-input
                  class="col"
                  floating-label
                  :label="$t('inspecciones.inspeccion_form.motivo')"
                  :form="rform"
                  type="textarea"
                  name="situacion.motivo"
                ></r-input>
              </f7-col>
            </f7-row>
          </ul>
        </f7-list>
      </f7-card-content>
    </r-form>
    <p class="margin-left margin-right text-align-center">{{$t('inspecciones.inspeccion_form.confirmacionFinTarea')}}</p>
    <f7-button
        fill
        icon-f7="checkmark_circle_fill"
        :text="$t('common.confirmar')"
        class="display-flex margin-left"
        @click="$refs.rform.saveItem()"
      ></f7-button>
    </f7-card>
      <!-- </div> -->
    </f7-page>
  </f7-popup>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rForm from "./../../../components/rForm.vue";
import rInput from "./../../../components/rInput.vue";
import Inspeccion from "./../../../js/db/Inspeccion.js";
import Utils from "./../../../js/Utils.js";

export default {
  components: {
    rForm,
    rInput
  },
  data() {
    return {
      Inspeccion,
      Utils,
      popupAbierto: false
    };
  },
  props:{
    inspeccion:{
      type: Object
    }
  },
  computed: {},
  methods: {
    getDefaultData: function(inspeccion) {
      if (!inspeccion) {
        return null;
      }
      if (!inspeccion.situacion) {
        inspeccion.situacion = {};
      }
      inspeccion.situacion.motivo = "Marcamos la tarea como terminada";
      return inspeccion;
      
    },
    preparado: function(item) {
      return item.estado && item.estado.estado && item.estado.estado == "EN_TALLER" && item.situacion && item.situacion.fechaPrevista;
    },
    openPopup: function() {
      const self = this;
      self.popupAbierto = true;
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      Inspeccion.terminarTarea(res).then(res => {
        // if (self.$refs.popup) {
        //   self.$refs.popup.close();
        // }
        if (self.$refs.rform) {
          self.$refs.rform.clear();
        }
        app.toast.create({
          text: self.$t("inspecciones.inspeccion_form.inspeccion_guardada"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        }).open();
        // si el usuario tiene rol técnico, se le redirige a la pantalla de inspecciones
        if (app.data.user.roles.includes("TECNICO") && self.$f7router.currentRoute.path != '/inspecciones/') {
          self.$f7router.navigate("/inspecciones/?reloadTable");
        } else {
          self.$emit("finished");
        }
      });
      if (self.$refs.popup) {
        self.$refs.popup.close();
      }
    }
  }
};
</script>