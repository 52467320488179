<template>
  <div v-if="type == 'badge'"  class="item-content item-input item-input-with-value">
    <div class="item-inner">
      <div v-if="label" class="item-title item-label item-floating-label">{{label}}</div>
      <slot name="label"></slot>
      <div class="item-input-wrap">
        <r-badge :text="text" :color="color"></r-badge>
      </div>
      <f7-link
        v-if="getShowDecorationLink()"
        class="input-suffix-btn r-input-little r-input-top"
        :icon-f7="decorationConfig.icon"
        :color="decorationConfig.color"
        v-tooltip="decorationConfig.tooltip"
        @click="decorationConfig.action()"
      ></f7-link>
    </div>
      <!-- <slot name="decoration" slot="inner-end"> -->
          <!-- :href="getDetailUrl()" -->
      <!-- </slot> -->
  </div>
  <div v-else-if="type == 'check' && !simple" class="item-content item-input item-input-with-value">
    <div class="item-inner">
      <div v-if="label" class="item-title item-label item-floating-label">{{label}}</div>
      <slot name="label"></slot>
      <div class="item-input-wrap">
        <f7-toggle 
          class="item-input-wrap"
          :checked="getValue()"
          @change="setValue($event.target.checked, $event.target)"
          :disabled="overrideReadonly ? readonly : readonly || form.readonly"
          v-on="$listeners"
        ></f7-toggle>
      </div>
    </div>
    <slot name="content"></slot>
  </div>
  <div v-else-if="type == 'check'">
    <f7-list>
      <f7-list-item 
        checkbox 
        v-on="$listeners" 
        :disabled="overrideReadonly ? readonly : readonly || form.readonly" 
        :title="label" 
        :checked="getValue()" 
        @change="setValue($event.target.checked, $event.target)"
      ></f7-list-item>
    </f7-list>
    <slot name="content"></slot>
  </div>
  <div v-else-if="type == 'checks'" class="item-content item-input item-input-with-value">
    <div class="item-inner item-checks">

      <div v-if="label" class="item-title item-label item-label-up item-floating-label">{{label}}</div>
      <slot name="label"></slot>
      <div class="item-input-wrap">
        <f7-list>
          <f7-list-item 
            v-for="(itm, idx) in dbItems"
            :key="name + 'check-' + idx"
            @change="setValue($event.target.checked, $event.target)"
            :checked="isCheckedChecks(itm.value)"
            checkbox 
            :name="name"
            :title="itm.name"
            v-tooltip="itm.name"
            :value="itm.value"
          ></f7-list-item>
        </f7-list>
      </div>
    </div>
    <slot name="content"></slot>
  </div>
  <f7-list-input
    v-else
    :value="getValue()"
    v-bind="{...$attrs,...$props}"
    autocomplete="no"
    @input="setValue($event.target.value, $event.target)"
    @change="setValue($event.target.value, $event.target)"
    @calendar:change="setValue($event, 'calendar')"
    v-on="$listeners"
    :error-message-force="!!form.errors[name]"
    :error-message="form.errors[name]"
    :readonly="overrideReadonly ? readonly : readonly || form.readonly"
    :class="{readonly:overrideReadonly ? readonly : readonly || form.readonly}"
    v-tooltip="viewTooltip ? (label ? label + ':<br>' : '') + (getValue() ? (type == 'datepicker' ? dateToString(getValue()) : getValue()) : '') : ''"
    :clear-button="!(overrideReadonly ? readonly : readonly || form.readonly) && clearButton"
    @input:clear="setValue(null)"
    :type="target == 'cordova' && type == 'number' ? 'text' : type"
  >
    <slot name="label" slot="label"></slot>
    <slot></slot>
    <slot name="media" slot="media"></slot>
    <slot name="content" slot="content"></slot>
    <slot name="inner-end" slot="inner-end"></slot>
  </f7-list-input>
  
</template>
<style>
input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-year-field,
input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-month-field,
input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-day-field,
input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-text {
  color: transparent;
}
.readonly input,
.readonly select {
  pointer-events: none;
}

.item-checks .list {
  max-height: 170px;
  overflow: auto;
}
input[type="number"] + .input-clear-button{
  right: 20px;
}
</style>
<script>
import DateUtils from "./../js/DateUtils";
import rBadge from './rBadge.vue';

export default {
  components: { rBadge },
  data() {
    return {
      self: this,
      target: process.env.TARGET
    };
  },
  methods: {
    isCheckedChecks: function (val) {
      const self = this;
      const arr = self.getValue();
      if (arr && arr.length && (arr.indexOf(val) > -1)) {
        return true;
      } 
      return false;
    },
    dateToString: function(date) {
      if (!date.length) {
        return "";
      }
      return DateUtils.dateFormat(date[0], 'DD/MM/YYYY');
    },
    getValue: function() {
      const self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        context = context[namespaces[i]];
      }
      if (self.type == "checks") {
        if (context) {
          if (self.saveAsString) {
            context = context.split(",");
          }
        } else {
          context = [];
        }
      } else if (self.type == "check") {
        context = context == "true" || context == true;
      } else if (self.type == "datepicker") {
        if (context) {
          var previousVal = context;
          if (typeof context == "string") {
            context = new Date(previousVal);
            if (isNaN(context.getTime())) {
              var dateParts = previousVal.split("/");
              // month is 0-based, that's why we need dataParts[1] - 1
              while (dateParts.length < 3) {
                dateParts.unshift(1);
              }
              context = new Date(dateParts[2], dateParts[1] - 1, + dateParts[0]); 
            }
          }
          context = [context];
        } else {
          context = [];
        }
      }
      return context;
    },
    setValue: function(val, a) {
      const self = this;
      if (self.type == "datepicker") {
        if (typeof val != 'undefined' && val != null && (!a || a != "calendar")) return;
        else if (val) {
          val = val[0];
          if (val) {
            var previousVal = self.getValue();
            if (previousVal[0]) {
              val.setHours(previousVal[0].getHours());
              val.setMinutes(previousVal[0].getMinutes());
            }
          }
        }
      } else if (self.type == "number") {
        if (typeof val != undefined && val != null && val != "" && !isNaN(val)) {
          val = parseFloat(val);
        } else if (typeof val != undefined && val != null && val != "") {
          if (val.indexOf(",") > -1) {
            val = val.replace(",", ".");
          }
          if (!isNaN(val)) {
            val = parseFloat(val);
          } else {
            val = "";
          }
        } else {
          val = "";
        }
      } else if (self.type == "checks") {
        var values = self.getValue();
        if (val) {
          values.push(a.value);
        } else {
          values.splice(values.indexOf(a.value), 1);
        }
        if (self.saveAsString) {
          val = "";
          if (values && values.length) {
            for (var i = 0; i < values.length; i++) {
              if (i > 0) {
                val = val + ",";
              }
              val = val + values[i];
            }
          }
        } else {
          val = values;
        }
      } else if (self.type == "check" && self.saveAsString) {
        val = val + "";
      } else if (val != undefined && self.allMayus){
        val = val.toUpperCase();
      }

      if (val != undefined && val === '') {
        if (self.type != "number") {
          val = undefined;
        }
      }

      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          self.$set(context, namespaces[i], val);
          self.$emit("change", val, self);
        } else if (context[namespaces[i]] == null) {
          self.$set(context, namespaces[i], {});
          self.$emit("change", {}, self);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
            self.$emit("change", [], self);
          } else {
            self.$set(context, namespaces[i], {});
            self.$emit("change", {}, self);
          }
        }
        context = context[namespaces[i]];
      }
    },
    getShowDecorationLink: function() {
      const self = this;
      if (self.decorationConfig) {
        return true;
      } else {
        return false;
      }
    }
  },    

  props: {
    name: {
      required: true,
      type: String
    },
    simple: {
      type: Boolean,
      default: false
    },
    allMayus: {
      type: Boolean,
      default: false
    },
    form: {
      required: false,
      type: Object,
      default: function() {
        return {};
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    overrideReadonly: {
      type: Boolean,
      default: false
    },
    viewTooltip: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      default: ""
    },
    clearButton: {
      type: Boolean,
      default: true
    },
    validation: {
      type: Object
    },
    dbItems: {
      type: Array
    },
    saveAsString: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    },
    color: {
      type: String
    },
    decorationConfig: {
      type: Object,
      default: null
    }
  },
  created() {
    var self = this;
    self.$emit("created", self);
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    var actualVal = self.getValue();
    if (self.validation) {
      self.form.validateFields[self.name] = self.validation;
    }
    if (self.type == 'select') {
      if (!actualVal) {
        var selected = self.$options._renderChildren.filter(function(c){
          return c.data && c.data.attrs && c.data.attrs.selected != undefined
        });
        if (selected && selected.length > 0) {
          var value = selected[0].data.attrs.value;
          if (value && value != null && value != '') {
            self.setValue(selected[0].data.attrs.value, self);
          }
        } else {
          self.setValue(null, self);
        }
      }
    } else if (self.type == 'check') {
      if (!actualVal) {
        self.setValue(false, self);
      }
    }
    self.$emit("mounted", self);
    /*self
      .$$(this.$el)
      .find("select")
      .on("mousedown", function(e) {
        if (self.readonly || self.form.readonly) {  
          e.preventDefault();
          this.blur();
          window.focus();
        }
      });*/
  },
  destroyed() {
    var self = this;
    if (self.validation) {
      delete self.form.validateFields[self.name];
    }
  }
};
</script>
<style scope>
a.input-suffix-btn.r-input-little {
  height: 100%;
  top: 0;
}
a.r-input-little i.f7-icons {
  font-size: 8pt;
}
a.r-input-top {
  margin-top: 0;
}
a.r-input-top i.f7-icons {
  vertical-align: top;
}
</style>
