import Marcas from './marcas.vue';
import MarcaForm from './marca-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/marcas/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('marcas', ['ADMINISTRATIVO'], Marcas),
    },
    {
        path: '/marcas/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('marcas/new', ['ADMINISTRATIVO'], MarcaForm)
    },
    {
        path: '/marcas/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('marcas/edit', ['ADMINISTRATIVO'], MarcaForm)
    },
    {
        path: '/marcas/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('marcas/view', ['ADMINISTRATIVO'], MarcaForm, { editable: false })
    },
];
export default routes;