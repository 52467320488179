import { render, staticRenderFns } from "./rDynamicList.vue?vue&type=template&id=5210c0ec&scoped=true"
import script from "./rDynamicList.vue?vue&type=script&lang=js"
export * from "./rDynamicList.vue?vue&type=script&lang=js"
import style0 from "./rDynamicList.vue?vue&type=style&index=0&id=5210c0ec&prod&scoped=true&lang=css"
import style1 from "./rDynamicList.vue?vue&type=style&index=1&id=5210c0ec&prod&lang=css"
import style2 from "./rDynamicList.vue?vue&type=style&index=2&id=5210c0ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5210c0ec",
  null
  
)

export default component.exports