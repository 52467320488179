import Pedidos from './pedidos.vue';
import PedidoForm from './pedido-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/pedidos/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('pedidos', ['ADMINISTRATIVO', 'INSPECTOR', 'RESPONSABLE'], Pedidos),
        //keepAlive: true
    },
    {
        path: '/pedidos/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('pedidos/new', ['ADMINISTRATIVO', 'INSPECTOR', 'RESPONSABLE'], PedidoForm)
    },
    {
        path: '/pedidos/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('pedidos/edit', [ 'ADMINISTRATIVO', 'RESPONSABLE'], PedidoForm)
    },
    {
        path: '/pedidos/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('pedidos/view', ['ADMINISTRATIVO', 'INSPECTOR', 'RESPONSABLE'], PedidoForm, { editable: false })
    },
];
export default routes;